export enum GooglePayEventType {
  ADD_GOOGLE_PAY_PAYMENT_GO_NEXT = "ADD_GOOGLE_PAY_PAYMENT_GO_NEXT",
  SET_IS_READY_TO_USE_GOOGLE_PAY = "SET_IS_READY_TO_USE_GOOGLE_PAY",
}

export interface SetGooglePayPayment {
  type: GooglePayEventType.ADD_GOOGLE_PAY_PAYMENT_GO_NEXT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentData: any; //Opaque to FE
}

export interface SetIsReadyToUseGooglePay {
  type: GooglePayEventType.SET_IS_READY_TO_USE_GOOGLE_PAY;
  isReadyToUseGooglePay: boolean;
}

export type GooglePayEvents = SetGooglePayPayment | SetIsReadyToUseGooglePay;
