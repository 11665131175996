import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@b2bportal/core-types";
import { getDisruptionOffersRequestParams } from "../store/selectors";
import { fetchDisruptionOffers } from "../thunks";

export const useFetchDisruptionOffers = (onNoValidParams?: () => void) => {
  const dispatch = useDispatch<AppDispatch>();

  const requestParams = useSelector(getDisruptionOffersRequestParams);

  return useCallback(() => {
    if (requestParams !== null) {
      dispatch(fetchDisruptionOffers(requestParams));
    } else {
      onNoValidParams && onNoValidParams();
    }
  }, [dispatch, onNoValidParams, requestParams]);
};

export const useFetchDisruptionOffersWithParams = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (tripId: string, fareId: string, numberOfPassengers: number) => {
      const requestParams = { tripId, fareId, numberOfPassengers };
      if (requestParams != null) {
        dispatch(fetchDisruptionOffers(requestParams));
      }
    },
    [dispatch]
  );
};
