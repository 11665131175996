import axios from "axios";

import { priceFreezeApi } from "@b2bportal/price-freeze-api";
import type { HotelsPriceFreezeExercisePartialMachineContext } from "../types";

export const fetchFrozenProductToExercise = async (
  context: HotelsPriceFreezeExercisePartialMachineContext
) => {
  const priceFreezeId = context.priceFreeze.priceFreezeId;

  const res = await priceFreezeApi(
    axios
  ).apiV3PricefreezeVoucherExerciseFindPost({ priceFreezeId });

  if (res.data.FindProductToExerciseResponse === "NotAvailable") {
    throw new Error("No product match found.");
  }

  if (res.data.matchingProduct.MatchingProduct !== "Hotel") {
    throw new Error("Matched product not hotel.");
  }

  return res.data.matchingProduct;
};
