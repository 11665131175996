// Do not use this as a default value for a Component props, because it will cause issues with Storybook
// Instead you can make the component take an optional value and use ?? to coalesce undefined values into
// this default regex. (see https://github.com/hopper-org/b2bportal/pull/6642)
export const emailRegex = new RegExp(
  import.meta && import.meta.env && import.meta.env.VITE_EMAIL_REGEX
    ? import.meta.env.VITE_EMAIL_REGEX
    : "^[a-zA-Z0-9]+(?:.?[a-zA-Z0-9]+)*(?:[_-]*(?:[a-zA-Z0-9]+.[a-zA-Z0-9]+)*[a-zA-Z0-9]*)*@[a-zA-Z0-9]+(?:(?:-*[a-zA-Z0-9]+)*.[a-zA-Z0-9]+)+$"
);

// First part of phone regex uses {2,3} since some phone numbers are starting with a 0 and the google-libphonenumber trims this leading 0
// As an example, a uruguayan phone number can look like (+598)0xxxxxxxx and be parsed to (+598) xxxxxxxx,
// which has 8 number and didn't pass this regex before the change
// Last part of the regex {3,8} allows longer phone numbers like +5491161578095 where the last part has 8 digits

export const phoneRegex = new RegExp(
  "^[+]?[(]?[0-9]{2,3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{3,8}$"
);
export const nameRegex = new RegExp(
  "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -]+$"
);
export const specialCharacterRegex = new RegExp("^[A-Za-z0-9]+$");
export const cpfRegex = new RegExp("^[-0-9.]+$");
export const passportRegex = new RegExp("^[a-zA-Z0-9]{6,16}$");
