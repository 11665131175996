import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import defaultStyles from "./IframeDialog.module.scss";
import { CoreUiComponents } from "@b2bportal/core-types";
import React from "react";
import { DialogContent, type DialogContentComponentProps } from "../Dialog";

/**
 * Higher-ordered UI component for <Dialog> containing <iframe>
 */
export const IframeDialogContent = React.forwardRef<
  HTMLDivElement,
  DialogContentComponentProps
>(({ children, ...props }, forwardedRef) => {
  const styles = useUiStyles(CoreUiComponents.IframeDialog, defaultStyles);

  const [block, _] = useModuleBEM(styles, CoreUiComponents.IframeDialog);

  return (
    <DialogContent {...props} ref={forwardedRef} className={block}>
      {children}
    </DialogContent>
  );
});
