import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { assign, type DoneInvokeEvent, sendParent } from "xstate";
import type { PartialParentWithTravelerContext } from "./context";
import {
  type SelectTravelerEvent,
  type SetCurrentTravelerEvent,
  TravelerEventTypes,
} from "./events";
import { ParentState, TravelerError } from "@checkout/index";
import { Traveler } from "@b2bportal/traveler-api/lib/api";

export const actions = {
  selectTraveler: assign(
    (ctx: PartialParentWithTravelerContext, event: SelectTravelerEvent) => {
      const travelerInformationCtx = ctx[ParentState.travelerInformation];
      const { travelerId, singleTravelerWorkflow, onUpdate = false } = event;
      const { selectedTravelerIds, travelers } = travelerInformationCtx;
      const newTravelerInfoCtx = { ...travelerInformationCtx };

      // Unselect the traveler if already exists in the selected list and action is not triggered by update
      if (!onUpdate && selectedTravelerIds.includes(travelerId)) {
        newTravelerInfoCtx.selectedTravelerIds = selectedTravelerIds.filter(
          (id) => id !== travelerId
        );
      } else {
        const newTraveler = travelers.find(
          (traveler) => traveler.id === travelerId
        );
        if (!newTraveler) return ctx;

        newTravelerInfoCtx.selectedTravelerIds = singleTravelerWorkflow
          ? [travelerId] // hotel and car have only one primary traveler
          : [...selectedTravelerIds, travelerId]; // air can have multiple travelers
      }

      return {
        ...ctx,
        [ParentState.travelerInformation]: newTravelerInfoCtx,
      };
    }
  ),

  updateTraveler: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<Traveler>
    ) => {
      const newTraveler = event.data;
      const { travelers } = ctx[ParentState.travelerInformation];
      const newTravelers = travelers.some(
        (traveler) => traveler.id === newTraveler.id
      )
        ? travelers.map((traveler) =>
            traveler.id === newTraveler.id ? newTraveler : traveler
          )
        : [...travelers, newTraveler];
      const newSelectedTravelerIds = [newTraveler.id];

      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.travelers`,
        newTravelers
      );
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.selectedTravelerIds`,
        newSelectedTravelerIds
      );
      return ctx;
    }
  ),

  setTravelers: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<Array<Traveler>>
    ) => {
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.travelers`,
        event.data
      );
      return ctx;
    }
  ),

  goPrevious: sendParent(TravelerEventTypes.PREVIOUS),

  handleDeleteTraveler: assign(
    (ctx: PartialParentWithTravelerContext, event: DoneInvokeEvent<string>) => {
      const deletedTravelerId = event.data;
      const selectedTravelerIds =
        ctx[ParentState.travelerInformation].selectedTravelerIds;
      ctx[ParentState.travelerInformation].selectedTravelerIds =
        selectedTravelerIds.filter((id) => deletedTravelerId !== id);

      const travelers = ctx[ParentState.travelerInformation].travelers;
      ctx[ParentState.travelerInformation].travelers = travelers.filter(
        (traveler) => traveler.id !== deletedTravelerId
      );
      return ctx;
    }
  ),

  setCurrentTraveler: assign(
    (ctx: PartialParentWithTravelerContext, event: SetCurrentTravelerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.currentTraveler`,
        event.traveler
      )
  ),

  setTravelersError: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<TravelerError>
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.error`,
        event.data
      )
  ),

  clearTravelerInformationError: assign(
    (ctx: PartialParentWithTravelerContext, _event) => {
      ctx[ParentState.travelerInformation].error = undefined;
      return ctx;
    }
  ),

  unselectAllTravelers: assign(
    (ctx: PartialParentWithTravelerContext, _event) => {
      ctx[ParentState.travelerInformation].selectedTravelerIds = [];
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
