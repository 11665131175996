import {
  type FirstDayOfWeek,
  type MonthType,
  type OnDatesChangeProps,
  useDatepicker,
} from "@datepicker-react/hooks";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getLang, useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink } from "../../../../../ActionLink";

import { Month } from "../Month";
import "./styles.scss";
import { firstWeekDay } from "../../component";
import type { PickerType } from "../../types";
import { Slot } from "../../../../../Slots";

export interface IHorizontalViewProps {
  startDate: Date | null;
  cleanedEndDate: Date | null;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  numberOfMonths: number;
  showPriceRangeTags?: boolean;
  priceTags?: string[];
  priceRangeTags: JSX.Element;
  className?: string;
  pickerType?: PickerType;
}

export const HorizontalView = ({
  startDate,
  cleanedEndDate,
  setStartDate,
  setEndDate,
  minAllowedDate,
  maxAllowedDate,
  numberOfMonths,
  showPriceRangeTags,
  priceRangeTags,
  priceTags,
  className,
  pickerType,
}: IHorizontalViewProps) => {
  const { brand } = useI18nContext();
  const handleDateChange = (props: OnDatesChangeProps) => {
    const { startDate, endDate } = props;
    setStartDate && setStartDate(startDate);
    setEndDate && setEndDate(endDate);
  };

  const {
    firstDayOfWeek,
    activeMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate,
    endDate: cleanedEndDate,
    focusedInput: null,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const [firstMonth, secondMonth] = activeMonths;

  const [currentFocusDate, setCurrentFocusDate] = useState<Date | undefined>(
    dayjs().toDate()
  );

  const [isDateFocused, setIsDateFocused] = useState<boolean>(true);

  const leftArrowDisabled = minAllowedDate
    ? isLessThanMonth(firstMonth, minAllowedDate)
    : false;

  const rightArrowDisabled = maxAllowedDate
    ? isGreaterThanMonth(secondMonth, maxAllowedDate)
    : false;

  const getYearMonthFloat = (date: Date | undefined) =>
    parseFloat(`${dayjs(date).format("YYYYMM")}`);

  useEffect(() => {
    const currentFocusYearMonth = getYearMonthFloat(currentFocusDate);
    const secondMonthYearMonth = getYearMonthFloat(secondMonth.date);
    const firstMonthYearMonth = getYearMonthFloat(firstMonth.date);
    if (currentFocusYearMonth > secondMonthYearMonth && !rightArrowDisabled)
      goToNextMonthsByOneMonth();

    if (currentFocusYearMonth < firstMonthYearMonth && !leftArrowDisabled)
      goToPreviousMonthsByOneMonth();

    if (dayjs(currentFocusDate).isBefore(dayjs(), "date"))
      setCurrentFocusDate(dayjs().toDate());
  }, [currentFocusDate]);

  useEffect(() => {
    if (dayjs(currentFocusDate).isBefore(dayjs(firstMonth.date), "month"))
      setCurrentFocusDate(
        dayjs(firstMonth.date).date(dayjs(currentFocusDate).date()).toDate()
      );
    if (dayjs(currentFocusDate).isAfter(dayjs(secondMonth.date), "month"))
      setCurrentFocusDate(
        dayjs(secondMonth.date).date(dayjs(currentFocusDate).date()).toDate()
      );
  }, [firstMonth, secondMonth]);

  const renderMonths = () =>
    [firstMonth, secondMonth].map((month: MonthType) => {
      return (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          firstDayOfWeek={firstDayOfWeek}
          date={month.date}
          className={className}
          currentFocusDate={currentFocusDate}
          setCurrentFocusDate={setCurrentFocusDate}
          setIsDateFocused={setIsDateFocused}
          isDateFocused={isDateFocused}
          priceTags={priceTags}
          monthFormat={brand?.calendarMonthFormat}
        />
      );
    });

  return (
    <Box className={clsx("horizontal-view-date-range-picker", className)}>
      <Slot
        id="desktop-calendar-date-label-controls"
        pickerType={pickerType}
        startDate={startDate}
        returnDate={cleanedEndDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Box className="date-range-picker-section">
        <Box className={clsx("month-shift-button-container", "left")}>
          <ActionLink
            className="month-shift-button"
            label="Go to previous months button"
            onClick={goToPreviousMonthsByOneMonth}
            content={
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                className="month-shift-icon"
              />
            }
            showTappableArea={true}
            disabled={leftArrowDisabled}
          />
        </Box>
        {renderMonths()}
        <Box className={clsx("month-shift-button-container", "right")}>
          <ActionLink
            className="month-shift-button"
            label="Go to next months button"
            onClick={goToNextMonthsByOneMonth}
            content={
              <FontAwesomeIcon
                icon={faAngleRight as IconProp}
                className="month-shift-icon"
              />
            }
            showTappableArea={true}
            disabled={rightArrowDisabled}
          />
        </Box>
      </Box>
      {showPriceRangeTags && (
        <Box className="price-range-tags-section">{priceRangeTags}</Box>
      )}
    </Box>
  );
};

const isLessThanMonth = (left: MonthType, right: Date) =>
  left.year < right.getFullYear() ||
  (left.year === right.getFullYear() && left.month <= right.getMonth());

const isGreaterThanMonth = (left: MonthType, right: Date) =>
  left.year > right.getFullYear() ||
  (left.year === right.getFullYear() && left.month >= right.getMonth());
