import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./HowItWorksDialog.module.scss";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { Icon } from "@b2bportal/core-ui";
import { TextWithExternalLink } from "../SharedComponents/TextWithExternalLink";

export interface HowItWorksDialogProps {
  minDelayHoursThreshold: number;
  maxAmountRefunded: string;
  maxAmountCovered: string;
  viewTermsLink: string;
  benefitsFromAirlineLink?: string;
  bulletLinePrefixIconName?: IconNameEnum;
  displayExternalLinksAsModal?: boolean;
}

export const HowItWorksDialog = ({
  minDelayHoursThreshold,
  maxAmountCovered,
  viewTermsLink,
  benefitsFromAirlineLink,
  bulletLinePrefixIconName,
  displayExternalLinksAsModal,
}: HowItWorksDialogProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.HowItWorksDialog;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t, brand } = useI18nContext();

  const productName = t("core-disruption.productName");
  const portalName = t("core-disruption.howItWorksDialog.portalName");

  const minDelayHoursThresoldText = t("timeCount.hour", {
    count: minDelayHoursThreshold,
  });

  const htsAddOnsLink =
    brand.urls.htsAddOns ?? "https://hts.hopper.com/legal/what-are-hts-add-ons";

  const MainBulletsSection = () => {
    return (
      <>
        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey="core-disruption.howItWorksDialog.bullets.one"
              values={{ portalName, maxAmountCovered }}
              components={[<span className={cn("bullet-one-rebook")} />]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 1,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey="core-disruption.howItWorksDialog.bullets.two"
              values={{
                portalName,
                maxAmountCovered,
                minDelayHoursThresoldText,
              }}
              components={[<span className={cn("bullet-two-refund")} />]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 2,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <span>{t("core-disruption.howItWorksDialog.bullets.three")}</span>
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 3,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />
      </>
    );
  };

  const ImportantInformationBulletsSection = ({
    displayExternalLinksAsModal,
  }) => {
    return (
      <>
        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey="core-disruption.howItWorksDialog.importantInformation.one"
              components={[
                <span className={cn("important-information-one-bold-text")} />,
              ]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 1,
            className: cn("bullet-icon"),
          }}
          iconBullet={{
            iconName: IconNameEnum.disruptionHowItWorksDialogInfoLineOne,
            className: cn("important-information-line-one-icon"),
          }}
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <TextWithExternalLink
              transI18nKey="core-disruption.howItWorksDialog.importantInformation.two"
              transValues={{}}
              transComponents={[]}
              linkUrl={benefitsFromAirlineLink ?? ""}
              linkClassName={cn("benefits-from-airline-link")}
              displayInModal={displayExternalLinksAsModal}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 2,
            className: cn("bullet-icon"),
          }}
          iconBullet={{
            iconName: IconNameEnum.disruptionHowItWorksDialogInfoLineTwo,
            className: cn("important-information-line-two-icon"),
          }}
        />
      </>
    );
  };

  return (
    <div className={block}>
      <Icon
        iconName={IconNameEnum.disruptionProtection}
        className={cn("header-icon")}
        useCurrentColor={false}
      />

      <h2 className={cn("dialog-title")}>
        {t("core-disruption.howItWorksDialog.title")}
      </h2>

      <span className={cn("bullets-title")}>
        <Trans
          i18nKey="core-disruption.howItWorksDialog.subtitle"
          values={{ minDelayHoursThreshold }}
          components={[<span className={cn("dialog-subtitle")} />]}
        />
      </span>
      <MainBulletsSection></MainBulletsSection>

      <span className={cn("important-information-title")}>
        {t("core-disruption.howItWorksDialog.importantInformation.title")}
      </span>
      <ImportantInformationBulletsSection
        displayExternalLinksAsModal={displayExternalLinksAsModal}
      ></ImportantInformationBulletsSection>

      <span className={cn("footer-text", { first: true })}>
        <TextWithExternalLink
          transI18nKey="core-disruption.howItWorksDialog.footer.one"
          transValues={{ productName }}
          transComponents={[
            <span className={cn("footer-text-one-bold-text")} />,
          ]}
          linkUrl={htsAddOnsLink}
          linkClassName={cn("see-here-link")}
          displayInModal={displayExternalLinksAsModal}
        />
      </span>

      <span className={cn("footer-text", { last: true })}>
        <TextWithExternalLink
          transI18nKey="core-disruption.howItWorksDialog.footer.two"
          transValues={{}}
          transComponents={[]}
          linkUrl={viewTermsLink}
          linkClassName={cn("terms-conditions-link")}
          displayInModal={displayExternalLinksAsModal}
        />
      </span>
    </div>
  );
};

type BulletItemProps = {
  className;
  textChildren: React.ReactNode;
  textClassName: string;
  numberBullet: {
    num: number;
    className: string;
  };
  iconBullet?: {
    iconName: IconNameEnum;
    className?: string;
  };
};

const BulletItem = ({
  className,
  textChildren,
  textClassName,
  ...props
}: BulletItemProps) => {
  return (
    <div className={className}>
      {props.iconBullet != null ? (
        <Icon {...props.iconBullet} useCurrentColor={false} />
      ) : (
        <span className={props.numberBullet.className}>
          {props.numberBullet.num}
        </span>
      )}
      <span className={textClassName}>{textChildren}</span>
    </div>
  );
};
