import common_translations from "../common.json";
import app from "./app.json";
import cars from "./cars.json";
import checkout from "./checkout.json";
import exchange from "./exchange.json";
import fintech from "./fintech.json";
import flights from "./flights.json";
import hotels from "./hotels.json";
import selfServe from "./self-serve.json";
import trips from "./trips.json";
import uber from "./uber.translation.json";
import ui from "./ui.json";
import wallet from "./wallet.json";
import hopper from "./hopper.translation.json";
import commbankAu from "./commbank-au.translation.json";
import hsbcSg from "./hsbc-sg.translation.json";
import smcc from "./smcc.translation.json";
import smccCars from "./smcc.cars.translation.json";
import tripadvisor from "./tripadvisor.translation.json";
import nubank from "./nubank.translation.json";
import nubank_provided from "./nubank.provided.json";
import volaris from "./volaris.translation.json";
import flair from "./flair.translation.json";
import hyatt from "./hyatt.translation.json";
import marriott from "./marriott.translation.json";
import lloyds from "./lloyds.translation.json";
import blue from "./blue.translation.json";
import medellin from "./medellin.translation.json";
import bootstrap from "./bootstrap.translation.json";
import virginAu from "./virgin-au.translation.json";
import banorte from "./banorte.translation.json";
import apacCars from "./apac.cars.translation.json";

const bundle = {
  ...common_translations,
  ...app,
  ...flights,
  ...hotels,
  ...cars,
  ...ui,
  ...checkout,
  ...selfServe,
  ...trips,
  ...fintech,
  ...exchange,
  ...wallet,
};
export default bundle;

export const en_defaultTranslations = bundle;

export const en_uberTranslations = uber;

export const en_hopperTranslations = hopper;

export const en_commbankAuTranslations = commbankAu;

export const en_hsbcSgTranslations = hsbcSg;

export const en_smccTranslations = smcc;
export const en_smccCarsTranslations = smccCars;

export const en_tripadvisorTranslations = tripadvisor;

export const en_volarisTranslations = volaris;

export const en_flairTranslations = flair;

export const en_hyattTranslations = hyatt;

export const en_marriottTranslations = marriott;

export const en_lloydsTranslations = lloyds;

export const en_blueTranslations = blue;

export const en_medellinTranslations = medellin;

export const en_bootstrapTranslations = bootstrap;

export const en_banorteTranslations = banorte;

export const en_virginAuTranslations = virginAu;

export const en_nubankTranslations = nubank;

export const en_nubankProvidedTranslations = nubank_provided;

export const en_apacCarsTranslations = apacCars;
