import type { Airline, TripContext } from "@b2bportal/air-booking-api";
import type { TripSummary } from "@b2bportal/air-shopping-api";

export enum FlightRatingsEnum {
  basic,
  standard,
  enhanced,
  premium,
  luxury,
}

export enum FlightShopType {
  PRICE_FREEZE_PURCHASE = "pf-purchase",
  PRICE_FREEZE_EXERCISE = "pf-exercise",
  CHFAR_EXERCISE = "chfar-exercise",
  DEFAULT = "default",
}

export enum ShopFilter {
  ShortLayover = "ShortLayover",
  NonStopNoLCC = "NonStopNoLCC",
  NoLCC = "NoLCC",
  NonStop = "NonStop",
  ShortLayoverNoLCC = "ShortLayoverNoLCC",
  NoFilter = "NoFilter",
}

export enum SliceStopCountFilter {
  DEFAULT = "DEFAULT",
  ANY_NUMBER = "ANY_NUMBER",
  NONE = "NONE",
  ONE_OR_LESS = "ONE_OR_LESS",
  TWO_OR_LESS = "TWO_OR_LESS",
}

export interface IBucketedDate {
  date: Date;
  bucket: number;
}

export interface IDateBucket {
  dates: string[];
  legend: string;
}

export interface IReturnSlicesByOutgoingId {
  [outgoingSliceId: string]: ITripIdsByReturnSlice;
}

export interface ITripIdsByReturnSlice {
  [returnSliceId: string]: TripSummaryId;
}

export type AirlineCode = Airline["code"];

export type AirlineMap = TripContext["airlines"];

export type AirportMap = TripContext["airports"];

export type TripSummaryMap = Record<string, TripSummary>;

export type TripSummaryId = string;

export * from "./eventTracking";
