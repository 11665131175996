import {
  datadogRum,
  type RumEvent,
  type RumInitConfiguration,
} from "@datadog/browser-rum";
import { APPLICATION_ID, CLIENT_TOKEN, DATADOG_SITE } from "./constants";

export type InitializeRumProps = Partial<RumInitConfiguration> & {
  service: string;
  enableSessionRecording: boolean;
  additionalBeforeSendActions?: (event: RumEvent) => void;
};

export const initializeRum = ({
  service,
  allowedTracingUrls,
  enableSessionRecording,
  trackUserInteractions = true,
  applicationId = APPLICATION_ID,
  clientToken = CLIENT_TOKEN,
  site = DATADOG_SITE,
  additionalBeforeSendActions = null,
  ...rest
}: InitializeRumProps) => {
  datadogRum.init({
    sessionSampleRate: 100,
    sessionReplaySampleRate: enableSessionRecording ? 100 : 0,
    useCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    trackResources: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls,
    applicationId,
    clientToken,
    site,
    service,
    trackUserInteractions,
    beforeSend: (event) => {
      // remove token and code from startSession url
      event.view.url = event.view.url.replace(
        /(token|code|auth_payload)=[^&]*/gm,
        "$1=REDACTED"
      );
      if (event.view.referrer) {
        event.view.referrer = event.view.referrer.replace(
          /(token|code|auth_payload)=[^&]*/gm,
          "$1=REDACTED"
        );
      }

      // Allow tenants to customize their beforeSend actions
      if (additionalBeforeSendActions) {
        additionalBeforeSendActions(event);
      }
      return true;
    },
    ...rest,
  });
};

export const startRumSessionReplayRecording = () => {
  datadogRum.startSessionReplayRecording();
};
