import {
  type IconNameEnum,
  useFintechUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFintechUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./FintechProductBullet.module.scss";
import { Icon } from "@components/ui";
import clsx from "clsx";
import type { ReactNode } from "react";

export interface FintechProductBulletProps {
  icon?: { iconName: IconNameEnum; className?: string };
  children: ReactNode;
  className?: string;
}

export const FintechProductBullet = (props: FintechProductBulletProps) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProductBullet;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={clsx(block, props.className)}>
      {props.icon != null && (
        <Icon
          iconName={props.icon.iconName}
          className={clsx(cn("icon"), props.icon.className)}
          useCurrentColor={false}
        />
      )}
      <span className={cn("text")}>{props.children}</span>
    </div>
  );
};
