import {
  FlightSearchQueryParams,
  type FlightSearchQueryParamValues,
  type IPassengerCounts,
  type ITripTerminus,
} from "@b2bportal/core-types";
import {
  createQueryParams,
  QueryParamType,
  useParseQueryParams,
} from "@b2bportal/core-utilities";
import { toJsDate } from "@hopper-b2b/common-utils";
import { useSelector } from "react-redux";

import { SliceStopCountFilter, TripCategory } from "../../../types";
import type { FlightSearchValues } from "../../search/store";
import { getSearchState } from "../../search/store/selectors";

export const useGetShopQueryParams = () => {
  const params = {
    [FlightSearchQueryParams.ORIGIN]: {
      type: QueryParamType.requiredString,
    },
    [FlightSearchQueryParams.DESTINATION]: {
      type: QueryParamType.requiredString,
    },
    [FlightSearchQueryParams.DEPARTURE_DATE]: {
      type: QueryParamType.requiredDate,
    },
    [FlightSearchQueryParams.RETURN_DATE]: {
      type: QueryParamType.optionalDate,
    },
    [FlightSearchQueryParams.TRIP_CATEGORY]: {
      type: QueryParamType.optionalString,
    },
    [FlightSearchQueryParams.ADULT_COUNT]: {
      type: QueryParamType.optionalNumber,
    },
    [FlightSearchQueryParams.CHILD_COUNT]: {
      type: QueryParamType.optionalNumber,
    },
    [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]: {
      type: QueryParamType.optionalNumber,
    },
    [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]: {
      type: QueryParamType.optionalNumber,
    },
  };

  return useParseQueryParams(params);
};

/**
 * Hook to check if the current search is the same as the one in the URL
 * by comparing the search values in the redux store with the URL search params
 */

export const useIsSameSearch = () => {
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    adultCount,
    childCount,
    infantInSeatCount,
    infantOnLapCount,
  } = useGetShopQueryParams();

  const searchValues = useSelector(getSearchState);

  /** TODO: compare the rest of the search values related with passenger selection */
  const isSameSearch =
    isSameLocation(origin, searchValues.origin) &&
    isSameLocation(destination, searchValues.destination) &&
    isSameDate(departureDate, searchValues.departureDate) &&
    isSameDate(returnDate, searchValues.returnDate) &&
    searchValues.tripCategory === tripCategory &&
    isSameNumber(adultCount, searchValues.passengerCounts.adultsCount) &&
    isSameNumber(childCount, searchValues.passengerCounts.childrenCount) &&
    isSameNumber(
      infantInSeatCount,
      searchValues.passengerCounts.infantsInSeatCount
    ) &&
    isSameNumber(
      infantOnLapCount,
      searchValues.passengerCounts.infantsOnLapCount
    );

  return isSameSearch;
};
const isSameNumber = (param: number | undefined, value: number) => {
  const parsedParam = param == null ? 0 : param;
  const valueAsNumber = isNaN(value) ? 0 : value;
  return valueAsNumber === parsedParam;
};

const isSameLocation = (code?: string, location?: ITripTerminus) => {
  return (
    (code == null && location == null) ||
    (code != null && location != null && location.id.code.code === code)
  );
};

const isSameDate = (queryParamDate?: string, storeDate?: string) => {
  return (
    (queryParamDate == null && storeDate == null) ||
    (queryParamDate != null &&
      storeDate != null &&
      storeDate === queryParamDate)
  );
};

export type BuildFlightShopQueryArgs =
  | {
      tripCategory: TripCategory.ROUND_TRIP;
      origin: ITripTerminus;
      destination: ITripTerminus;
      passengerCounts?: IPassengerCounts;
      departureDate: Date | string;
      returnDate: Date | string;
    }
  | {
      tripCategory: TripCategory.ONE_WAY;
      origin: ITripTerminus;
      destination: ITripTerminus;
      passengerCounts?: IPassengerCounts;
      departureDate: Date | string;
    };

export const buildFlightShopQuery = (params: BuildFlightShopQueryArgs) => {
  const { passengerCounts, origin, destination, departureDate } = params;

  try {
    const flightShopQueryObject = {
      [FlightSearchQueryParams.ORIGIN]: origin.id.code.code,
      [FlightSearchQueryParams.DESTINATION]: destination.id.code.code,
      [FlightSearchQueryParams.DEPARTURE_DATE]: toJsDate(departureDate),
      ...(params.tripCategory === TripCategory.ROUND_TRIP
        ? {
            [FlightSearchQueryParams.RETURN_DATE]: toJsDate(params.returnDate),
          }
        : {}),
      [FlightSearchQueryParams.TRIP_CATEGORY]: params.tripCategory,
      [FlightSearchQueryParams.STOP_OPTIONS]: SliceStopCountFilter.ANY_NUMBER,
      [FlightSearchQueryParams.ADULT_COUNT]: passengerCounts?.adultsCount,
      [FlightSearchQueryParams.CHILD_COUNT]: passengerCounts?.childrenCount,
      [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]:
        passengerCounts?.infantsOnLapCount,
      [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]:
        passengerCounts?.infantsInSeatCount,
    };
    return createQueryParams(flightShopQueryObject).toString();
  } catch (e) {
    console.error(e);
  }
};

export const buildFlightShopValuesFromQueryParams = (
  queryParams: Partial<FlightSearchQueryParamValues>
): Omit<FlightSearchValues, "origin" | "destination"> => {
  const {
    departureDate,
    returnDate,
    tripCategory,
    adultCount,
    childCount,
    infantInSeatCount,
    infantOnLapCount,
  } = queryParams;

  // Origin and destination are not returned given you can not build a location from a code
  return {
    departureDate,
    returnDate,
    tripCategory:
      tripCategory === TripCategory.ONE_WAY
        ? TripCategory.ONE_WAY
        : TripCategory.ROUND_TRIP,
    passengerCounts: {
      adultsCount: adultCount != null ? adultCount : 0,
      childrenCount: childCount != null ? childCount : 0,
      infantsInSeatCount: infantInSeatCount != null ? infantInSeatCount : 0,
      infantsOnLapCount: infantOnLapCount != null ? infantOnLapCount : 0,
    },
  };
};
