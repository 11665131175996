import {
  CartContext,
  type LodgingShopStateWithoutValue,
  ParentState,
} from "@checkout/index";
import type { LodgingStateWithoutValue } from "./types";
import { Product } from "@b2bportal/purchase-api";
import dayjs from "dayjs";
import {
  Amenity,
  type PricingV1,
  type LodgingPriceQuote,
  LodgingPriceQuoteEnum,
} from "@b2bportal/lodging-api";
import { getFulfilledProductsReservationId } from "../../Cart/CartFulfill/selectors";
import { State } from "xstate";

export const getSelectedLodging = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.selectedLodging;

export const getSelectedLodgingCityName = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedLodging = getSelectedLodging(state);
  return selectedLodging?.lodging?.city;
};

export const getSelectedLodgingCountryName = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedLodging = getSelectedLodging(state);
  return selectedLodging?.lodging?.country;
};

export const getLodgingCityName = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.lodgingCity;

export const getLodgingShop = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop;

export const getSelectedRoom = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.selectedRoom;

export const getSelectedRoomRateId = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.selectedRoomRateId;

export const getCheckInPolicies = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.checkInPolicies;

export const getLodgingShopTrackingProperties = (
  state: LodgingShopStateWithoutValue
) => state.context?.lodgingShop?.trackingProperties;

export const getSelectedRoomProduct = (state: LodgingShopStateWithoutValue) => {
  const selectedRoom = getSelectedRoom(state);
  const roomRateId = getSelectedRoomRateId(state);
  return selectedRoom?.products?.find((p) => p.rateId.value === roomRateId);
};

export const getSelectedOpaqueRoomRate = (
  state: LodgingShopStateWithoutValue
) => {
  return (
    state.context.lodgingShop.selectedOpaqueQuoteRequest ||
    getSelectedRoomProduct(state)?.opaqueQuoteRequest
  );
};

export const getHotelRoomBasePriceTotal = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.baseRate?.fiat;
};

export const getHotelRoomTotal = (state: LodgingShopStateWithoutValue) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.total;
};

export const getHotelRoomPriceTotal = (state: LodgingShopStateWithoutValue) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.total?.fiat;
};

// tripTotal = total + feeBreakdown
export const getHotelRoomTripTotal = (state: LodgingShopStateWithoutValue) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.tripTotal?.fiat;
};

export const getHotelRoomTripTotalNoDiscounts = (
  state: LodgingShopStateWithoutValue
) => {
  // This sometimes returns a value lower than the total price. May be due to a negative discount?
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.totalDiscountAware?.priceWithNoDiscounts.fiat;
};

export const getHotelRoomBasePricePerNightTotal = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.perNightBaseRate?.fiat;
};

export const getHotelRoomPricePerNightTotal = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.perNightTotal?.fiat;
};

export const getSelectedLodgingAmenities = (
  state: LodgingShopStateWithoutValue
) => {
  const lodging = getSelectedLodging(state);
  return lodging?.lodging?.amenities;
};

export const getHotelRoomTaxBreakdown = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.taxBreakdown;
};

export const getHotelRoomFeeBreakdownTotal = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.feeBreakdown?.total;
};

export const getHotelRoomCancellationPolicy = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.cancellationPolicy;
};

export const getHotelRoomCancellationPolicyV2 = (
  state: LodgingShopStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.cancellationPolicyV2;
};

export const getHotelFromDate = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.fromDate;

export const getHotelUntilDate = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.untilDate;

export const getTotalNights = (state: LodgingShopStateWithoutValue) => {
  const fromDateString = getHotelFromDate(state);
  const untilDateString = getHotelUntilDate(state);

  if (fromDateString && untilDateString) {
    const fromDate = dayjs(fromDateString);
    const untilDate = dayjs(untilDateString);
    return untilDate.diff(fromDate, "days");
  }
};

export const getSelectedRoomsCount = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.selectedRoomsCount;

export const getAdvance = (state: LodgingShopStateWithoutValue) => {
  const fromDateString = getHotelFromDate(state);

  if (fromDateString) {
    const fromDate = dayjs(fromDateString);
    return dayjs().diff(fromDate, "days");
  }
};

export const getGuestSelection = (state: LodgingShopStateWithoutValue) => {
  return state.context.lodgingShop.guests;
};

export const getCheckInInstructions = (state: LodgingShopStateWithoutValue) => {
  return state.context.lodgingShop.checkInInstructions;
};

export const getGuestCount = (state: LodgingShopStateWithoutValue) => {
  return (
    (state.context.lodgingShop.guests?.adults ?? 0) +
    (state.context.lodgingShop.guests?.children?.length ?? 0)
  );
};

export const getQuotedLodgingProduct = (
  state: Pick<State<CartContext>, "context">
): LodgingPriceQuote | undefined => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;
  const hotelProduct = breakdown?.products?.find(
    (p) => p.product.type === Product.Hotel
  );
  return hotelProduct?.product?.value;
};

export const getLodgingMinimumAge = (
  state: LodgingStateWithoutValue
): number | undefined => {
  return state.context[ParentState.lodgingShop].checkInInstructions?.minimumAge;
};

export const getQuotedLodgingProductPricingV1 = (
  state: LodgingStateWithoutValue
): PricingV1 | undefined => {
  const quotedLodgingProduct = getQuotedLodgingProduct(state);

  switch (quotedLodgingProduct?.LodgingPriceQuote) {
    /*
      export interface PriceQuoteV2 {
        'pricingV1': PricingV1;
        'additionalPolicies': Array<CheckInPolicy>;
        'LodgingPriceQuote': LodgingPriceQuoteEnum;
      }
    */
    case LodgingPriceQuoteEnum.PriceQuoteV2:
      return quotedLodgingProduct?.pricingV1;
    /*
      export interface PriceQuoteV1 {
        'cancellationPolicy': CancellationPolicy;
        'checkInInstructions': CheckInInstructions;
        'priceChange'?: PriceChange;
        'cancellationPolicyV2'?: CancellationPolicyV2;
        'LodgingPriceQuote': LodgingPriceQuoteEnum;
        'pricing': Pricing;
      }
    */
    case LodgingPriceQuoteEnum.PriceQuoteV1:
    case LodgingPriceQuoteEnum.PriceQuote:
    default:
      return undefined;
  }
};

export const getQuotedLodgingBreakdown = (state: LodgingStateWithoutValue) => {
  return state.context.cartQuote.quoteBreakdown;
};

export const getConfirmationId = (state: LodgingStateWithoutValue) => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const hotelProduct = products.find((p) => p.type === Product.Hotel);
  return hotelProduct.value.customerReservationId;
};

export const getAvailabilityLink = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.availabilityLink;

export const getSelectedRoomMaxAdults = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.roomMaxAdults;

export const getSelectedRoomMediaUrl = (state: LodgingShopStateWithoutValue) =>
  state.context.lodgingShop.roomMediaUrl;

export const getLodgingFulfillTrackingProperties = (
  state: LodgingStateWithoutValue
) => {
  const lodging = getSelectedLodging(state);
  const lodgingData = lodging?.lodging;
  const room = getSelectedRoom(state);
  const selectedRoomProduct = getSelectedRoomProduct(state);
  const amenities = lodgingData?.amenities?.map(
    (amenityStatus) => amenityStatus.amenity
  );
  const reservationId = getFulfilledProductsReservationId(state);

  const {
    properties: lodgingProperties = {},
    encryptedProperties: lodgingEncryptedProperties,
  } = lodging?.trackingPropertiesV2 || {};

  const lodgingFulfillTrackingProperties = {
    properties: {
      ...lodgingProperties,
      amenities_shown: amenities,
      advance: `${dayjs(state.context.lodgingShop?.fromDate).diff(
        dayjs(),
        "day"
      )} days`,
      booking_advance_days: dayjs(state.context.lodgingShop?.fromDate).diff(
        dayjs(),
        "day"
      ),
      brand: null,
      breakfast_included_shown: amenities?.includes(Amenity.FreeBreakfast),
      chain: null,
      check_in_date: state.context.lodgingShop?.fromDate,
      check_out_date: state.context.lodgingShop?.untilDate,
      city_name: lodgingData?.city,
      comparable_count: lodging?.price?.comparePrices?.length,
      country_name: lodgingData?.country,
      daily_net_rate_excluding_taxes_fees_usd:
        selectedRoomProduct?.perNightTotal?.fiat?.value,
      description_language: lodging?.description,
      hopper_preferred_hotel: lodging?.isPreferred,
      lodging_id: lodgingData?.id,
      lodging_name: lodgingData?.name,
      lodging_star_rating: lodgingData?.starRating.toString(),
      lodging_tripadvisor_rating: null,
      lodging_type: lodging?.lodgingCollection,
      lowest_comparable_provider_name: null,
      number_of_photos: lodgingData?.media?.length,
      number_of_reviews: lodgingData?.tripAdvisorReviews?.reviewsCount,
      number_of_videos: null,
      original_provider_owed_now_currency: null,
      reservation_id: reservationId,
      room_index_in_shop: null,
      room_media_count: room?.roomInfo?.media?.length,
      room_media_owner: null,
      room_media_shown: room?.roomInfo?.media?.length > 0,
      room_type_count: null,
      room_type_name: room?.roomInfo?.name,
      source_currency: lodging?.price?.totalPrice?.fiat?.currencyCode,
      total_provider_cost_including_taxes_fees_usd: null,
    },
    ...(lodgingEncryptedProperties && {
      encryptedProperties: lodgingEncryptedProperties,
    }),
  };

  return {
    ...lodgingFulfillTrackingProperties,
  };
};

export const getLocalStorageKey = (state: LodgingStateWithoutValue) => {
  return state.context.localStorageKey;
};
