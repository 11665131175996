import { unserializableMonthBucket } from "@b2bportal/core-utilities";
import { isEqual, toPairs } from "lodash-es";
import { createSelector } from "reselect";

import type { FlightState } from "../../../store";
import { TripCategory } from "../../../types";
import { type FlightSearchValues, initialFilterOptions } from "./slice";

const validateFlightSearchValues = ({
  origin,
  destination,
  departureDate,
  returnDate,
  tripCategory,
  passengerCounts,
}: FlightSearchValues) => {
  if (origin == null || destination == null || departureDate == null) {
    return undefined;
  }
  const baseParams = {
    origin,
    destination,
    departureDate,
    passengerCounts,
  };
  if (tripCategory === TripCategory.ONE_WAY) {
    return { ...baseParams, tripCategory };
  }
  if (returnDate == null) {
    return undefined;
  }
  return { ...baseParams, returnDate, tripCategory };
};

export const asDate = (stringDate?: string) => {
  if (stringDate != null && stringDate !== "") {
    return new Date(stringDate);
  }
  return null;
};

export const getTripCategory = (state: FlightState) =>
  state.flights.flightSearch.tripCategory;

export const getOrigin = (state: FlightState) =>
  state.flights.flightSearch.origin;

export const getDestination = (state: FlightState) =>
  state.flights.flightSearch.destination;

export const getDepartureDate = (state: FlightState) =>
  state.flights.flightSearch.departureDate;

export const getFormattedDepartureDate = createSelector(
  getDepartureDate,
  (departureDate) => {
    return asDate(departureDate);
  }
);

export const getReturnDate = (state: FlightState) =>
  state.flights.flightSearch.returnDate;

export const getAdultsCount = (state: FlightState) =>
  state.flights.flightSearch.passengerCounts.adultsCount;

export const getChildrenCount = (state: FlightState) =>
  state.flights.flightSearch.passengerCounts.childrenCount;

export const getInfantsInSeatCount = (state: FlightState) =>
  state.flights.flightSearch.passengerCounts.infantsInSeatCount;

export const getInfantsOnLapCount = (state: FlightState) =>
  state.flights.flightSearch.passengerCounts.infantsOnLapCount;
export const getFormattedReturnDate = createSelector(
  getReturnDate,
  (returnDate) => {
    return asDate(returnDate);
  }
);

export const isInitializingValues = (state: FlightState) =>
  state.flights.flightSearch.isInitializingValues;

export const getMobileSearchActiveStep = (state: FlightState) =>
  state.flights.flightSearch.mobileSearchActiveStep;

export const getStopsOption = (state: FlightState) =>
  state.flights.flightSearch.stopsOption;

export const getMaxPriceFilter = (state: FlightState) =>
  state.flights.flightSearch.maxPriceFilter;

export const getFareclassOptionFilter = (state: FlightState) =>
  state.flights.flightSearch.fareclassOptionFilter;

export const getOutboundDepartureTimeRange = (state: FlightState) =>
  state.flights.flightSearch.outboundDepartureTimeRange;

export const getOutboundArrivalTimeRange = (state: FlightState) =>
  state.flights.flightSearch.outboundArrivalTimeRange;

export const getReturnDepartureTimeRange = (state: FlightState) =>
  state.flights.flightSearch.returnDepartureTimeRange;

export const getReturnArrivalTimeRange = (state: FlightState) =>
  state.flights.flightSearch.returnArrivalTimeRange;

export const getAirlineFilter = (state: FlightState) =>
  state.flights.flightSearch.airlineFilter;

export const getAirportFilter = (state: FlightState) =>
  state.flights.flightSearch.airportFilter;

export const getFlightNumberFilter = (state: FlightState) =>
  state.flights.flightSearch.flightNumberFilter;

// Get current filter states
// Note: the max price filter state lives in shop/selectors (it relies on data from the trip summaries page)
export const getHasSetStopsOption = (state: FlightState) =>
  state.flights.flightSearch.stopsOption !== initialFilterOptions.stopsOption;

export const getStopsOptionFilter = (state: FlightState) =>
  state.flights.flightSearch.stopsOption;

export const getHasSetAirlineFilter = (state: FlightState) =>
  state.flights.flightSearch.airlineFilter.length > 0;

export const getHasSetAirportFilter = (state: FlightState) =>
  state.flights.flightSearch.airportFilter.length > 0;

export const getHasSetFlightNumberFilter = (state: FlightState) =>
  state.flights.flightSearch.flightNumberFilter.length > 0;

export const getHasSetFareClassFilter = (state: FlightState) =>
  Object.values(state.flights.flightSearch.fareclassOptionFilter).includes(
    true
  );

export const getHasSetDepartureTimeRange = createSelector(
  [getOutboundDepartureTimeRange, getReturnDepartureTimeRange],
  (outboundDepartureTimeRange, returnDepartureTimeRange) => {
    return (
      !isEqual(
        outboundDepartureTimeRange,
        initialFilterOptions.outboundDepartureTimeRange
      ) ||
      !isEqual(
        returnDepartureTimeRange,
        initialFilterOptions.returnDepartureTimeRange
      )
    );
  }
);

export const getHasSetArrivalTimeRange = createSelector(
  [getOutboundArrivalTimeRange, getReturnArrivalTimeRange],
  (outboundArrivalTimeRange, returnArrivalTimeRange) => {
    return (
      !isEqual(
        outboundArrivalTimeRange,
        initialFilterOptions.outboundArrivalTimeRange
      ) ||
      !isEqual(
        returnArrivalTimeRange,
        initialFilterOptions.returnArrivalTimeRange
      )
    );
  }
);

export const hasSelectedDates = createSelector(
  getDepartureDate,
  getReturnDate,
  getTripCategory,
  (departureDate, returnDate, tripCategory): boolean => {
    return (
      (tripCategory === TripCategory.ROUND_TRIP &&
        departureDate != null &&
        returnDate != null) ||
      (tripCategory === TripCategory.ONE_WAY && departureDate != null)
    );
  }
);

export const getActiveFiltersCount = createSelector(
  [
    getStopsOption,
    getFareclassOptionFilter,
    getHasSetDepartureTimeRange,
    getHasSetArrivalTimeRange,
  ],
  (
    stopsOption,
    fareclassOptionFilter,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange
  ) => {
    let filtersCount = 0;

    if (stopsOption !== initialFilterOptions.stopsOption) {
      filtersCount += 1;
    }

    if (
      !isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      )
    ) {
      filtersCount += 1;
    }

    if (hasSetDepartureTimeRange) {
      filtersCount += 1;
    }

    if (hasSetArrivalTimeRange) {
      filtersCount += 1;
    }

    return filtersCount;
  }
);

export const passengerCountSelector = (state: FlightState) =>
  state.flights.flightSearch.passengerCounts;

export const getPassengersTotal = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount) =>
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount
);

export const getSelectedFareShelfRatings = createSelector(
  getFareclassOptionFilter,
  (fareClassFilters) => {
    const filtersToShelfRatingMap = {
      basic: 0,
      standard: 1,
      enhanced: 2,
      premium: 3,
      luxury: 4,
    };
    const selectedFareShelf = toPairs(fareClassFilters).reduce(
      (ratings, [filter, isActive]) =>
        isActive ? [...ratings, filtersToShelfRatingMap[filter]] : ratings,
      [] as Array<number>
    );

    return selectedFareShelf.length ? selectedFareShelf : [0, 1, 2, 3, 4];
  }
);

export const viewCalendarEventProperties = createSelector(
  getOrigin,
  getDestination,
  getTripCategory,
  (origin, destination, tripCategory) => ({
    origin: `${origin?.id.code.regionType}/${origin?.id.code.code}`,
    destination: `${destination?.id.code.regionType}/${destination?.id.code.code}`,
    tripCategory,
  })
);

export const getInitialSearchValues = createSelector(
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getTripCategory,
  (depDate, dest, origin, retDate, tripCategory) => ({
    departureDate: depDate,
    destination: dest,
    origin,
    returnDate: retDate,
    tripCategory,
  })
);

export const getSearchValues = createSelector(
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getTripCategory,
  (depDate, destination, origin, retDate, tripCategory) => ({
    departureDate: depDate,
    destination: destination,
    origin: origin,
    returnDate: retDate,
    tripCategory,
  })
);

export const getHasSetOutboundTimeRange = createSelector(
  [getOutboundDepartureTimeRange, getOutboundArrivalTimeRange],
  (outboundDepartureTimeRange, outboundArrivalTimeRange) => {
    return (
      !isEqual(
        outboundDepartureTimeRange,
        initialFilterOptions.outboundDepartureTimeRange
      ) ||
      !isEqual(
        outboundArrivalTimeRange,
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
  }
);

export const getHasSetReturnTimeRange = createSelector(
  [getReturnDepartureTimeRange, getReturnArrivalTimeRange, getTripCategory],
  (returnDepartureTimeRange, returnArrivalTimeRange, tripCategory) => {
    return (
      tripCategory === TripCategory.ROUND_TRIP &&
      (!isEqual(
        returnDepartureTimeRange,
        initialFilterOptions.returnDepartureTimeRange
      ) ||
        !isEqual(
          returnArrivalTimeRange,
          initialFilterOptions.returnArrivalTimeRange
        ))
    );
  }
);

export const getSearchState = createSelector(
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getTripCategory,
  passengerCountSelector,
  (
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts
  ) => ({
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts,
  })
);

export const getValidSearchState = createSelector(getSearchState, (params) =>
  validateFlightSearchValues(params)
);

export const getPricePrediction = (state: FlightState) =>
  state.flights.flightSearch.pricePrediction;

export const getPriceTags = createSelector(
  getPricePrediction,
  (pricePrediction) => pricePrediction.priceTags
);

export const getPricePredictionDepartureMonths = createSelector(
  getPricePrediction,
  (pricePrediction) =>
    unserializableMonthBucket(pricePrediction.departureMonths)
);

export const isFlightPredictionLoading = createSelector(
  getPricePrediction,
  (pricePrediction) => pricePrediction.isLoading
);

// Active search state selectors

export const getActiveAdultsCount = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.passengerCounts.adultsCount;

export const getActiveChildrenCount = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.passengerCounts.childrenCount;

export const getActiveInfantsInSeatCount = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.passengerCounts
    .infantsInSeatCount;

export const getActiveInfantsOnLapCount = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.passengerCounts.infantsOnLapCount;
export const getActiveTripCategory = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.tripCategory;

export const getActiveOrigin = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.origin;

export const getActiveDestination = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.destination;

export const getActiveDepartureDate = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.departureDate;

export const getActiveReturnDate = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.returnDate;

export const getActivePassengerCounts = (state: FlightState) =>
  state.flights.flightSearch.searchFormValues.passengerCounts;

export const getSearchFormValues = createSelector(
  getActiveOrigin,
  getActiveDestination,
  getActiveDepartureDate,
  getActiveReturnDate,
  getActiveTripCategory,
  getActivePassengerCounts,
  (
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts
  ) => ({
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts,
  })
);
