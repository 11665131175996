import type { HotelPriceFreezeVoucher } from "@b2bportal/core-types";
import type {
  LodgingMediaAsset,
  ShopResponseAvailable,
} from "@b2bportal/lodging-api";
import type { MatchingProductHotel } from "@b2bportal/price-freeze-api";
import { Payment, type PaymentOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import {
  ParentState,
  type HotelsPriceFreezeExercisePartialMachineContext,
  type HotelPriceFreezeExerciseDiscount,
  type HotelPriceFreezeExerciseDataLoaderMachineContext,
} from "@checkout/index";
import type { LodgingData } from "@hopper-b2b/types";
import { assign, type DoneInvokeEvent } from "xstate";

export const actions = {
  addPriceFreezeVoucher: assign(
    (
      context: HotelsPriceFreezeExercisePartialMachineContext,
      event: DoneInvokeEvent<HotelPriceFreezeVoucher>
    ) => {
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.priceFreezeVoucher`,
        event.data
      );
    }
  ),
  addFrozenProductToExercise: assign(
    (
      context: HotelsPriceFreezeExercisePartialMachineContext,
      event: DoneInvokeEvent<MatchingProductHotel>
    ) => {
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.frozenProductToExercise`,
        event.data
      );
    }
  ),
  setPriceFreezeDiscount: assign(
    (context: HotelsPriceFreezeExercisePartialMachineContext) => {
      const frozenProductToExercise =
        context.priceFreeze.frozenProductToExercise;

      if (frozenProductToExercise === undefined) {
        return context;
      }

      const priceFreezeDiscount: HotelPriceFreezeExerciseDiscount = {
        type: Payment.PriceFreezeExerciseDiscount,
        value: frozenProductToExercise,
      };
      const filterOutPayments = (
        context[ParentState.cartUpdate].addPayments ?? []
      ).filter(
        (p: PaymentOpaqueValue) =>
          p.type !== Payment.PriceFreezeExerciseDiscount
      );
      return setContextWithKey(
        context,
        `${ParentState.cartUpdate}.addPayments`,
        [...filterOutPayments, priceFreezeDiscount]
      );
    }
  ),
  addFetchedLodgingData: assign(
    (
      context: HotelPriceFreezeExerciseDataLoaderMachineContext,
      event: DoneInvokeEvent<LodgingData>
    ) => {
      return setContextWithKey(
        context,
        "lodgingShop.selectedLodging",
        event.data
      );
    }
  ),
  addPriceFreezeDataToLodgingContext: assign(
    (context: HotelPriceFreezeExerciseDataLoaderMachineContext) => {
      const { priceFreezeVoucher, frozenProductToExercise } =
        context.priceFreeze;

      const shopResponse = context.priceFreeze.frozenProductToExercise
        .opaqueShopResponse.value as ShopResponseAvailable;

      const voucherFrozenProduct = priceFreezeVoucher?.frozenProduct;
      const guestsSelection = frozenProductToExercise.guestsSelection;

      const selectedLodging = (() => {
        /**
         * If we don't have lodging shop data, that means the tenant didn't provide a custom function to fetch the lodging data.
         * In this case, we'll need to add the lodging data to the context manually from the price freeze voucher and frozen product to exercise.
         */
        if (context.lodgingShop.selectedLodging) {
          return { lodging: context.lodgingShop.selectedLodging };
        }

        return {
          lodging: {
            city: voucherFrozenProduct?.hotelCity,
            name: voucherFrozenProduct?.hotelName,
            country: voucherFrozenProduct?.hotelCountry,
            id: shopResponse?.lodgingId,
            media: [
              { url: voucherFrozenProduct?.hotelImage },
            ] as LodgingMediaAsset[],
            address: {
              LodgingAddress: "Exact",
              line1: [
                voucherFrozenProduct?.hotelCity,
                voucherFrozenProduct?.hotelCountry,
              ]
                .filter(Boolean)
                .join(", "),
            },
          },
        };
      })();

      const selectedRoom = shopResponse.roomInfoProducts.find(Boolean);
      const selectedRoomRateId =
        selectedRoom.products.find(Boolean).rateId.value;
      const selectedRoomsCount = 1;
      const fromDate = voucherFrozenProduct.stayDateRange.from;
      const untilDate = voucherFrozenProduct.stayDateRange.until;
      const guests = guestsSelection
        ? {
            adults: guestsSelection.adults,
            children: guestsSelection.agesOfChildren,
          }
        : { adults: 1, children: [] };

      const checkInInstructions = shopResponse.checkInInstructions;
      const checkInPolicies = checkInInstructions.policies;
      const trackingProperties = {
        properties: {},
        encryptedProperties: [],
      };

      return setContextWithKey(context, "lodgingShop", {
        selectedLodging,
        selectedRoom,
        selectedRoomRateId,
        trackingProperties,
        fromDate,
        untilDate,
        guests,
        checkInInstructions,
        checkInPolicies,
        selectedRoomsCount,
      });
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
