import { useEffect, useMemo, useState } from "react";
import {
  type IconNameEnum,
  useFintechUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFintechUiComponents } from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  type FintechProductBulletProps,
  FintechProductInfoLinksSection,
  FintechProductOptionsSection,
} from "@components/core-fintech-ui";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import defaultStyles from "./FintechProduct.module.scss";

export interface SelectionOfferItemProp {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: JSX.Element;
  selectedContent: FintechProductBulletProps;
}

export enum FintechProductInfoLinksPlacement {
  BelowOffers = "BelowOffers",
  AboveOffers = "AboveOffers",
}

export enum FintechProductInfoLinksLayout {
  Horizontal = "Horizontal",
  Vertical = "Vertical",
}

export interface FintechProductProps {
  title: string;
  iconName: IconNameEnum;
  body: JSX.Element;
  selectionOffers: {
    options: SelectionOfferItemProp[];
  };
  howItWorksDialog: JSX.Element;
  viewTermsLink: string;
  optionsSectionUI?: {
    showTitle?: boolean;
  };
  // To support portals that require different positioning of info links (FintechProductInfoLinksSection)
  infoLinksUI?: {
    placement?: FintechProductInfoLinksPlacement;
    layout?: FintechProductInfoLinksLayout;
    suffixIcon?: { iconName: IconNameEnum; className?: string };
    prefixIcon?: { iconName: IconNameEnum; className?: string };
    displayViewTermsAsModal?: boolean;
  };
  radioButtonPosition: "start" | "end";
  showCloseButtonInDialog?: boolean;
}

export const FintechProduct = (props: FintechProductProps) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProduct;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const [showSelectedOfferSummary, setShowSelectedOfferSummary] =
    useState(false);

  return (
    <div className={clsx(block)}>
      <div
        className={cn("header-container", {
          "show-selected-offer-summary": showSelectedOfferSummary,
        })}
      >
        <Icon
          className={cn("header-image")}
          useCurrentColor={false}
          iconName={props.iconName}
        />
        <h3 className={cn("header-title")}>{props.title}</h3>
      </div>
      <Body
        {...props}
        cn={cn}
        showSelectedOfferSummary={showSelectedOfferSummary}
        setShowSelectedOfferSummary={setShowSelectedOfferSummary}
      />
    </div>
  );
};

const Body = (
  props: FintechProductProps & {
    cn: (className: string) => string;
    showSelectedOfferSummary: boolean;
    setShowSelectedOfferSummary: (isSeleted: boolean) => void;
  }
) => {
  const { isDesktopAndUp } = useDeviceType();

  const {
    cn,
    showSelectedOfferSummary,
    setShowSelectedOfferSummary,
    selectionOffers,
    infoLinksUI = {
      placement: FintechProductInfoLinksPlacement.BelowOffers,
      layout: FintechProductInfoLinksLayout.Horizontal,
      displayViewTermsAsModal: true,
    },
    howItWorksDialog,
    viewTermsLink,
    showCloseButtonInDialog,
    radioButtonPosition,
    optionsSectionUI = {
      showTitle: false,
    },
    body,
  } = props;

  const selectedOffer = useMemo(
    () => selectionOffers.options.find((offer) => offer.selected),
    [selectionOffers.options]
  );

  useEffect(
    function autoShowSelectedOfferSummaryGivenPreselectedOfferOnDesktop() {
      if (!isDesktopAndUp) {
        return;
      }

      if (selectedOffer == null) {
        return;
      }

      setShowSelectedOfferSummary(true);
    },
    [isDesktopAndUp, selectedOffer, setShowSelectedOfferSummary]
  );

  const SelectionOffersAndInfoLinks = () => {
    if (
      infoLinksUI?.placement === FintechProductInfoLinksPlacement.AboveOffers
    ) {
      return (
        <>
          <FintechProductInfoLinksSection
            howItWorksDialog={howItWorksDialog}
            viewTermsLink={viewTermsLink}
            layout={infoLinksUI.layout}
            showCloseButtonInDialog={showCloseButtonInDialog}
            suffixIcon={infoLinksUI.suffixIcon}
            prefixIcon={infoLinksUI.prefixIcon}
          />
          <FintechProductOptionsSection
            selectionOffers={selectionOffers}
            setShowSelectionRow={setShowSelectedOfferSummary}
            radioButtonPosition={radioButtonPosition}
          />
        </>
      );
    } else {
      return (
        <>
          <FintechProductOptionsSection
            selectionOffers={selectionOffers}
            showTitle={optionsSectionUI.showTitle}
            setShowSelectionRow={setShowSelectedOfferSummary}
            radioButtonPosition={radioButtonPosition}
          />
          <FintechProductInfoLinksSection
            howItWorksDialog={howItWorksDialog}
            viewTermsLink={viewTermsLink}
            layout={infoLinksUI.layout}
            showCloseButtonInDialog={showCloseButtonInDialog}
            suffixIcon={infoLinksUI.suffixIcon}
            prefixIcon={infoLinksUI.prefixIcon}
            displayViewTermsAsModal={infoLinksUI.displayViewTermsAsModal}
          />
        </>
      );
    }
  };

  return selectedOffer != null && showSelectedOfferSummary ? (
    <SelectedOfferSummary
      cn={cn}
      selectedOffer={selectedOffer}
      onEditClick={() => setShowSelectedOfferSummary(false)}
    />
  ) : (
    <>
      {body}
      <SelectionOffersAndInfoLinks />
    </>
  );
};

const SelectedOfferSummary = (props: {
  cn: (className: string) => string;
  selectedOffer: SelectionOfferItemProp;
  onEditClick: () => void;
}) => {
  const { t } = useI18nContext();

  const { selectedOffer, onEditClick, cn } = props;

  return (
    <div className={cn("selected-offer-summary")}>
      {selectedOffer.selectedContent.icon != null && (
        <Icon
          iconName={selectedOffer.selectedContent.icon.iconName}
          className={cn("selected-offer-summary-icon")}
          useCurrentColor={false}
        />
      )}

      <div className={cn("selected-offer-summary-content")}>
        {selectedOffer.selectedContent.children}
      </div>

      <button
        onClick={onEditClick}
        className={cn("selected-offer-summary-edit-button")}
      >
        {t("editProtection")}
      </button>
    </div>
  );
};
