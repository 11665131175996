import type { PriceFreezeVoucher } from "@b2bportal/price-freeze-api";

export const MILLISECONDS_IN_A_DAY = 86400000;

// @PF-TODO: Add this to the BE or BFF layer
/**
 * @deprecated This should come from the API
 */
export const getVoucherExpiry = (voucher: PriceFreezeVoucher) => {
  return (
    new Date(voucher.purchasedAt).getTime() +
    voucher.serviceDetails.serviceDurationDays * MILLISECONDS_IN_A_DAY
  );
};

// @PF-TODO: Add this to the BE or BFF layer
/**
 * @deprecated This should come from the API
 */
export const isVoucherExpired = (voucher: PriceFreezeVoucher) =>
  voucher.voucherState.State === "Valid" &&
  Date.now() > getVoucherExpiry(voucher);

// @PF-TODO: Add this to the BE or BFF layer
/**
 * @deprecated This should come from the API
 */
export const isVoucherInactive = (voucher: PriceFreezeVoucher) =>
  voucher.voucherState.State !== "Valid" || isVoucherExpired(voucher);

// @PF-TODO: Add this to the BE or BFF layer
/**
 * @deprecated This should come from the API
 */
export const isVoucherActive = (voucher: PriceFreezeVoucher) =>
  voucher.voucherState.State === "Valid" && !isVoucherExpired(voucher);
