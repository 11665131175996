import { ParentState } from "@hopper-b2b/checkout";
import { assign } from "xstate";
import {
  GooglePayEventType,
  type SetGooglePayPayment,
  type SetIsReadyToUseGooglePay,
} from "./events";
import type { PartialParentContextWithGooglePay } from "./types";

export const actions = {
  [GooglePayEventType.ADD_GOOGLE_PAY_PAYMENT_GO_NEXT]: assign(
    (
      context: PartialParentContextWithGooglePay,
      event: SetGooglePayPayment
    ) => {
      context[ParentState.googlePay].paymentData = event.paymentData;
      return context;
    }
  ),
  [GooglePayEventType.SET_IS_READY_TO_USE_GOOGLE_PAY]: assign(
    (
      context: PartialParentContextWithGooglePay,
      event: SetIsReadyToUseGooglePay
    ) => {
      context[ParentState.googlePay].isReadyToUseGooglePay =
        event.isReadyToUseGooglePay;
      return context;
    }
  ),
};
