import type {
  PriceFreezeOffer,
  PriceFreezeVoucher,
} from "@b2bportal/price-freeze-api";

import { HotelPriceFreezeOffer, HotelPriceFreezeVoucher } from "./types";

export const isHotelPriceFreezeVoucher = (
  voucher: PriceFreezeVoucher
): voucher is HotelPriceFreezeVoucher => {
  return voucher.frozenProduct.FrozenProduct === "Hotel";
};

export const isHotelPriceFreezeOffer = (
  offer: PriceFreezeOffer
): offer is HotelPriceFreezeOffer => {
  return offer.frozenProduct.FrozenProduct === "Hotel";
};
