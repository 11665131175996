import React from "react";
import type { SvgrComponent } from "@hopper-b2b/ui";

import { ReactComponent as Add } from "../../assets/client/add.svg";
import { ReactComponent as Airplane } from "../../assets/client/airplane.svg";
import { ReactComponent as BrandAlertIcon } from "../../assets/client/alert-brand.svg";
import { ReactComponent as ErrorAlertIcon } from "../../assets/client/alert-error.svg";
import { ReactComponent as WarningAlertIcon } from "../../assets/client/alert-warning.svg";
import { ReactComponent as CirclePlane } from "../../assets/client/circle-plane.svg";
import { ReactComponent as CloseModalPrimary } from "../../assets/client/close-modal.svg";
import { ReactComponent as CrossOutSignIcon } from "../../assets/client/cross-out-sign.svg";
import { ReactComponent as DropdownIcon } from "../../assets/client/dropdown.svg";
import { ReactComponent as ExternalLink } from "../../assets/client/external-link.svg";
// Flair
import { ReactComponent as AlertCircled } from "../../assets/client/flair/alert-circled.svg";
import { ReactComponent as Calendar } from "../../assets/client/flair/calendar.svg";
import { ReactComponent as CarryOnBag } from "../../assets/client/flair/carry-on-bag.svg";
import { ReactComponent as RestrictionsChecked } from "../../assets/client/flair/check-circle.svg";
import { ReactComponent as CheckboxChecked } from "../../assets/client/flair/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "../../assets/client/flair/checkbox-unchecked.svg";
import { ReactComponent as CheckedBag } from "../../assets/client/flair/checked-bag.svg";
import { ReactComponent as ChevronBold } from "../../assets/client/flair/chevron-bold.svg";
import { ReactComponent as ChevronDown } from "../../assets/client/flair/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/client/flair/chevron-up.svg";
import { ReactComponent as Clock } from "../../assets/client/flair/clock.svg";
import { ReactComponent as Close } from "../../assets/client/flair/close.svg";
import { ReactComponent as CloseModal } from "../../assets/client/flair/close.svg";
import { ReactComponent as DisruptionGuarantee } from "../../assets/client/flair/disruption-guarantee.svg";
import { ReactComponent as ErrorStateIcon } from "../../assets/client/flair/error-state.svg";
import { ReactComponent as RestrictionsGeneric } from "../../assets/client/flair/format-list-bulleted.svg";
import { ReactComponent as Info } from "../../assets/client/flair/info.svg";
import { ReactComponent as LeftArrow } from "../../assets/client/flair/left-arrow.svg";
import { ReactComponent as MaintenanceIcon } from "../../assets/client/flair/loading/maintenance.svg";
import { ReactComponent as LocationMarker } from "../../assets/client/flair/location-marker.svg";
import { ReactComponent as Menu } from "../../assets/client/flair/menu.svg";
import { ReactComponent as MultiPlanes } from "../../assets/client/flair/multi-planes.svg";
import { ReactComponent as RestrictionsPayment } from "../../assets/client/flair/payment.svg";
import { ReactComponent as PlaneBack } from "../../assets/client/flair/plane-back.svg";
import { ReactComponent as PlaneForward } from "../../assets/client/flair/plane-forward.svg";
import { ReactComponent as AirplaneArrive } from "../../assets/client/flair/plane-landing.svg";
import { ReactComponent as PlaneLanding } from "../../assets/client/flair/plane-landing.svg";
import { ReactComponent as AirplaneDepart } from "../../assets/client/flair/plane-takeoff.svg";
import { ReactComponent as PlaneTakeoff } from "../../assets/client/flair/plane-takeoff.svg";
import { ReactComponent as QuestionCircled } from "../../assets/client/flair/question-circled.svg";
import { ReactComponent as RestrictionsClose } from "../../assets/client/flair/restrictions-close.svg";
import { ReactComponent as RightArrow } from "../../assets/client/flair/right-arrow.svg";
import { ReactComponent as SeatSelection } from "../../assets/client/flair/seats.svg";
import { ReactComponent as ShieldPayment } from "../../assets/client/flair/shield-payment.svg";
import { ReactComponent as Star } from "../../assets/client/flair/star.svg";
import { ReactComponent as Guest } from "../../assets/client/flair/user.svg";
import { ReactComponent as FlightArrival } from "../../assets/client/flight-arrival.svg";
import { ReactComponent as InfoCircleBrandIcon } from "../../assets/client/info-circle-brand.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/client/info-icon.svg";
import { ReactComponent as SelfCheckBag } from "../../assets/client/self-check-bag.svg";
import { ReactComponent as Subtract } from "../../assets/client/subtract.svg";

import { IconName } from "../Icon";
import "./styles.scss";

type IconComponentMap = {
  [name in IconName]: SvgrComponent;
};

export const iconComponentMap: IconComponentMap = {
  [IconName.Airplane]: Airplane,
  [IconName.CirclePlane]: CirclePlane,
  [IconName.CloseModal]: CloseModal,
  [IconName.CrossOutSign]: CrossOutSignIcon,
  [IconName.Dropdown]: DropdownIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.ErrorState]: ErrorStateIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.BrandAlert]: BrandAlertIcon,
  [IconName.FlightArrival]: FlightArrival,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoCircleBrand]: InfoCircleBrandIcon,
  [IconName.SeatSelection]: SeatSelection,
  [IconName.SelfCheckBag]: SelfCheckBag,
  [IconName.CarryOnBaggage]: CarryOnBag,
  [IconName.CheckedBaggage]: CheckedBag,
  [IconName.TripProtection]: DisruptionGuarantee,
  [IconName.RestrictionsChecked]: RestrictionsChecked,

  [IconName.RestrictionsPayment]: RestrictionsPayment,
  [IconName.RestrictionsGeneric]: RestrictionsGeneric,
  [IconName.LocationMarker]: LocationMarker,
  [IconName.Guest]: Guest,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.ExternalLink]: ExternalLink,
  [IconName.Add]: Add,
  [IconName.Subtract]: Subtract,
  [IconName.AirplaneDepart]: AirplaneDepart,
  [IconName.AirplaneArrive]: AirplaneArrive,
  [IconName.CloseModalPrimary]: CloseModalPrimary,
  // Flair.
  [IconName.B2BAirplaneIcon]: PlaneForward,
  [IconName.RestrictionsClose]: RestrictionsClose,
  [IconName.AlertCircled]: AlertCircled,
  [IconName.Calendar]: Calendar,
  [IconName.CheckboxChecked]: CheckboxChecked,
  [IconName.CheckboxUnchecked]: CheckboxUnchecked,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronUp]: ChevronUp,
  [IconName.Clock]: Clock,
  [IconName.Close]: Close,
  [IconName.Info]: Info,
  [IconName.LeftArrow]: LeftArrow,
  [IconName.Menu]: Menu,
  [IconName.PlaneBack]: PlaneBack,
  [IconName.PlaneLanding]: PlaneLanding,
  [IconName.PlaneTakeoff]: PlaneTakeoff,
  [IconName.QuestionCircled]: QuestionCircled,
  [IconName.RightArrow]: RightArrow,
  [IconName.ShieldPayment]: ShieldPayment,
  [IconName.MaintenanceIcon]: MaintenanceIcon,
  [IconName.Star]: Star,
  [IconName.ChevronBold]: ChevronBold,
};

export interface IconComponentProps {
  name: IconName;
  className?: string;
  ariaLabel?: string | null;
}

export const IconComponent = ({
  name,
  ariaLabel,
  ...props
}: IconComponentProps): React.ReactElement => {
  const IconComponent = iconComponentMap[name] as SvgrComponent;
  return (
    <IconComponent
      {...props}
      className={`${props.className} icon-component`}
      aria-label={ariaLabel ?? name}
    />
  );
};
