import { getObjectKeysAsObject } from "@checkout/helpers";
import type { CommonParentContext } from "@checkout/states/common";
import type { HotelsPriceFreezeExercisePartialMachineContext } from "./types";

export const guards = {
  priceFreezeCheckoutAvailable: (
    ctx: HotelsPriceFreezeExercisePartialMachineContext & CommonParentContext
  ) =>
    ctx.featureFlags.enableHotelPriceFreeze && !!ctx.priceFreeze.priceFreezeId,

  priceFreezeVoucherNotAvailable: (
    ctx: HotelsPriceFreezeExercisePartialMachineContext
  ) => ctx.priceFreeze.priceFreezeVoucher === undefined,

  frozenProductToExerciseAvailable: (
    ctx: HotelsPriceFreezeExercisePartialMachineContext
  ) => ctx.priceFreeze.frozenProductToExercise !== undefined,

  frozenProductToExerciseNotAvailable: (
    ctx: HotelsPriceFreezeExercisePartialMachineContext
  ) => ctx.priceFreeze.frozenProductToExercise === undefined,
};

export const GuardTypes = getObjectKeysAsObject(guards);
