import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionOffer,
  DisruptionTrackingEvents,
  FintechCoverageType,
  FintechProductOption,
  FintechProductType,
} from "@hopper-b2b/types";
import { DetailHalfSheet, FintechPurchase } from "@hopper-b2b/ui";
import {
  HeaderPayload,
  useEnableFintechPurchaseErrorState,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientContext } from "../../../../App";
import { IStoreState } from "../../../../reducers/types";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer";
import { setSelectedMissedConnectionOffer } from "../../actions/actions";
import {
  getMissedConnectionOffer,
  getSelectedMissedConnectionOffer,
} from "../../reducer";
import { DisruptionContentProps } from "../DisruptionOffer";

const OPEN_DISRUPTION_DETAILS = "open_missed_connection_details";

export const MissedConnectionOffer = ({
  goToNextStep,
  hideContinueButton,
}: DisruptionContentProps) => {
  const { setHeader } = useUberBridge();
  const { t, formatCurrency } = useI18nContext();
  const enableFintechPurchaseErrorState = useEnableFintechPurchaseErrorState();
  const clientContext = useContext(ClientContext);

  const dispatch = useDispatch();

  const missedConnectionOffer = useSelector(getMissedConnectionOffer);
  const selectedOffer = useSelector(getSelectedMissedConnectionOffer);

  const [learnMoreOpen, setLearnMoreOpen] = useState<boolean>(false);

  const noCoverageSelected = selectedOffer === null;

  const missedConnectionOfferQuote = missedConnectionOffer?.quotes?.[0];
  const missedConnectionOfferCost = useMemo(
    () =>
      formatCurrency(missedConnectionOfferQuote?.pricePerPax?.fiat?.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [formatCurrency, missedConnectionOfferQuote]
  );

  // Clear selection on load
  useEffect(() => {
    if (enableFintechPurchaseErrorState) {
      dispatch(setSelectedMissedConnectionOffer(selectedOffer));
    }
  }, [dispatch, selectedOffer]);

  // Go to next step if no offer
  useEffect(() => {
    if (!missedConnectionOffer) {
      goToNextStep?.();
      return null;
    }
  });

  useEffect(() => {
    trackEvent({
      eventName: DisruptionTrackingEvents.VIEWED_MISSED_CONNECTION,
      properties: undefined,
    });
  }, []);

  useEffect(() => {
    if (learnMoreOpen) {
      trackEvent({
        eventName: DisruptionTrackingEvents.TAP_MISSED_CONNECTION_LEARN_MORE,
        properties: undefined,
      });
    }
  }, [learnMoreOpen]);

  const handleSelectLearnMore = useCallback(() => {
    setLearnMoreOpen(!learnMoreOpen);
  }, [learnMoreOpen]);

  useEffect(() => {
    setHeader({
      customButtons: [
        {
          icon: "CIRCLEI",
          action: {
            name: OPEN_DISRUPTION_DETAILS,
            callback: handleSelectLearnMore,
          },
        },
      ],
    } as HeaderPayload);
  }, [handleSelectLearnMore, setHeader, t]);

  const handleOfferSelection = useCallback(
    (offer: DisruptionOffer) => {
      dispatch(setSelectedMissedConnectionOffer(offer));

      if (offer) {
        trackEvent({
          eventName: DisruptionTrackingEvents.ADDED_MISSED_CONNECTION,
          properties: missedConnectionOfferQuote?.trackingProperties,
        });
      } else {
        trackEvent({
          eventName: DisruptionTrackingEvents.DECLINED_MISSED_CONNECTION,
          properties: missedConnectionOfferQuote?.trackingProperties,
        });
      }
    },
    [dispatch, missedConnectionOfferQuote?.trackingProperties]
  );

  useEffect(() => {
    if (missedConnectionOffer && !enableFintechPurchaseErrorState) {
      handleOfferSelection(missedConnectionOffer);
      dispatch(setSelectedMissedConnectionOffer(missedConnectionOffer));
    }
  }, [dispatch, handleOfferSelection, MissedConnectionOffer]);

  const options: FintechProductOption[] = useMemo(() => {
    return [
      {
        value: FintechCoverageType.Full,
        title: t("disruptionPurchase.fullCoverage"),
        cost: missedConnectionOfferCost,
        description: [
          t("disruptionPurchase.rebookTitle"),
          t("disruptionPurchase.refundTitle"),
        ],
        selected: selectedOffer === missedConnectionOffer,
        onClick: () => handleOfferSelection(missedConnectionOffer),
        ctaLabel: t("acceptCta"),
      },
      {
        value: FintechCoverageType.None,
        title: t("disruptionPurchase.noCoverage"),
        cost: formatCurrency(0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        description: [t("disruptionPurchase.description")],

        selected: noCoverageSelected,
        onClick: () => handleOfferSelection(null),
        ctaLabel: t("declineCta"),
      },
    ];
  }, [
    formatCurrency,
    handleOfferSelection,
    noCoverageSelected,
    missedConnectionOffer,
    missedConnectionOfferCost,
    selectedOffer,
    t,
  ]);

  const selectedTrip = useSelector(selectedTripSelector);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = useSelector((state: IStoreState) =>
    tripDetailsSelector(state, tripId)
  );

  const airlineCodes = useMemo(() => {
    if (tripDetails?.slices) {
      return tripDetails?.slices.flatMap((slice) => {
        return slice?.segmentDetails.map((segment) => {
          return segment?.airlineCode;
        });
      });
    }
    return [];
  }, [tripDetails]);

  const jetBlue = useMemo(
    () => airlineCodes.every((airline) => airline === "B6"),
    [airlineCodes]
  );

  const headerSubtitle = useMemo(
    () =>
      jetBlue
        ? t("missedConnectionInfoPopup.airlineAgreementDescription", {
            airline: "Jetblue",
          })
        : t("missedConnectionInfoPopup.description"),
    [jetBlue, t]
  );

  return (
    <div className="disruption-purchase">
      <FintechPurchase
        className="disruption"
        options={options}
        headerTitle={t("missedConnectionInfoPopup.header")}
        headerSubtitle={headerSubtitle}
        headerImgSrc={
          clientContext?.assets && clientContext?.assets["missedConnection"]
        }
        clientAssets={clientContext.assets}
        ctaLabel={
          selectedOffer && !noCoverageSelected
            ? t("acceptCta")
            : t("declineCta")
        }
        handleContinue={hideContinueButton ? undefined : goToNextStep}
        disabled={selectedOffer === undefined}
      />
      <DetailHalfSheet
        productType={FintechProductType.MissedConnection}
        open={learnMoreOpen}
        onClose={handleSelectLearnMore}
        header={t("missedConnectionInfoPopup.header")}
        title={t("disruptionInfoModal.title")}
        description={
          <I18nMarkup tKey={"missedConnectionInfoPopup.moreInformation"} />
        }
        descriptionAdditional
      />
    </div>
  );
};
