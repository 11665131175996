import { createSelector } from "@reduxjs/toolkit";
import { DisruptionState } from "../../../store/slice";
import {
  getNumberOfPassnegers,
  selectedTripSelector,
} from "@b2bportal/core-flights";
import {
  DisruptionOffer,
  DisruptionQuote,
} from "@b2bportal/air-disruption-api";
import { FintechMarketplaceProductOfferMeta } from "@b2bportal/core-fintech-ui";
import { ExternalResource } from "@b2bportal/core-types";

export const getDisruptionOffersRequestParams = createSelector(
  (state) => selectedTripSelector(state),
  (state) => getNumberOfPassnegers(state),
  (selectedTrip, numberOfPassengers) => {
    const tripId = selectedTrip.tripId;
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    if (tripId && fareId) {
      return {
        tripId,
        fareId,
        numberOfPassengers,
      };
    } else {
      return null;
    }
  }
);

export const getFetchDisruptionOffersCallState = (state: DisruptionState) =>
  state.disruption.disruptionOffers.fetchDisruptionOffersCallState;

export const getFetchDisruptionOffersLastRequest = (state: DisruptionState) =>
  state.disruption.disruptionOffers.lastRequest;

export const getFetchedDisruptionOffers = (state: DisruptionState) =>
  state.disruption.disruptionOffers.disruptionOffers;

export const getDisruptionOfferToShow = (
  state: DisruptionState
): DisruptionOffer | undefined =>
  state.disruption.disruptionOffers.disruptionOffers?.[0];

export const getFetchedDisruptionOfferQuotes = createSelector(
  getFetchedDisruptionOffers,
  (offers) => {
    return offers.flatMap((offer) => offer.quotes);
  }
);

export const getSelectedDisruptionOffer = (state: DisruptionState) => {
  return state.disruption.disruptionOffers.selectedDisruptionOffer;
};

export const getSelectedDisruptionTrackingProps = (state: DisruptionState) => {
  return state.disruption.disruptionOffers.selectedDisruptionOffer?.offer
    ?.quotes[0]?.trackingProperties;
};

export const getFetchedDisruptionFintechMarketplaceProductOffers =
  createSelector(
    getFetchDisruptionOffersCallState,
    getFetchedDisruptionOfferQuotes,
    (callState, quotes) =>
      ({
        state: callState,
        data: quotes.map<FintechMarketplaceProductOfferMeta<DisruptionQuote>>(
          (offer: DisruptionQuote) => ({
            premiumAmount: offer.premiumAmount,
            payload: offer,
          })
        ),
      } as ExternalResource<
        Array<FintechMarketplaceProductOfferMeta<DisruptionQuote>>
      >)
  );
