import React from "react";
import Add from "../../assets/client/add.svg";
import Airplane from "../../assets/client/airplane.svg";
import BrandAlertIcon from "../../assets/client/alert-brand.svg";
import ErrorAlertIcon from "../../assets/client/alert-error.svg";
import WarningAlertIcon from "../../assets/client/alert-warning.svg";
import CirclePlane from "../../assets/client/circle-plane.svg";
import CloseModal from "../../assets/client/close-modal.svg";
import CrossOutSignIcon from "../../assets/client/cross-out-sign.svg";
import DropdownIcon from "../../assets/client/dropdown.svg";
import ExternalLink from "../../assets/client/external-link.svg";
// Flair
import AlertCircled from "../../assets/client/flair/alert-circled.svg";
import Calendar from "../../assets/client/flair/calendar.svg";
import CarryOnBag from "../../assets/client/flair/carry-on-bag.svg";
import RestrictionsChecked from "../../assets/client/flair/check-circle.svg";
import CheckboxChecked from "../../assets/client/flair/checkbox-checked.svg";
import CheckboxUnchecked from "../../assets/client/flair/checkbox-unchecked.svg";
import CheckedBag from "../../assets/client/flair/checked-bag.svg";
import ChevronBold from "../../assets/client/flair/chevron-bold.svg";
import ChevronDown from "../../assets/client/flair/chevron-down.svg";
import ChevronUp from "../../assets/client/flair/chevron-up.svg";
import Clock from "../../assets/client/flair/clock.svg";
import Close from "../../assets/client/flair/close.svg";
import DisruptionGuarantee from "../../assets/client/flair/disruption-guarantee.svg";
import ErrorStateIcon from "../../assets/client/flair/error-state.svg";
import RestrictionsGeneric from "../../assets/client/flair/format-list-bulleted.svg";
import Info from "../../assets/client/flair/info.svg";
import LeftArrow from "../../assets/client/flair/left-arrow.svg";
import MaintenanceIcon from "../../assets/client/flair/loading/maintenance.svg";
import LocationMarker from "../../assets/client/flair/location-marker.svg";
import Menu from "../../assets/client/flair/menu.svg";
import MultiPlanes from "../../assets/client/flair/multi-planes.svg";
import RestrictionsPayment from "../../assets/client/flair/payment.svg";
import PlaneBack from "../../assets/client/flair/plane-back.svg";
import PlaneForward from "../../assets/client/flair/plane-forward.svg";
import PlaneLanding from "../../assets/client/flair/plane-landing.svg";
import AirplaneArrive from "../../assets/client/flair/plane-landing.svg";
import AirplaneDepart from "../../assets/client/flair/plane-takeoff.svg";
import PlaneTakeoff from "../../assets/client/flair/plane-takeoff.svg";
import QuestionCircled from "../../assets/client/flair/question-circled.svg";
import RestrictionsClose from "../../assets/client/flair/restrictions-close.svg";
import RightArrow from "../../assets/client/flair/right-arrow.svg";
import SeatSelection from "../../assets/client/flair/seats.svg";
import ShieldPayment from "../../assets/client/flair/shield-payment.svg";
import Star from "../../assets/client/flair/star.svg";
import Guest from "../../assets/client/flair/user.svg";
import FlightArrival from "../../assets/client/flight-arrival.svg";
import InfoCircleBrandIcon from "../../assets/client/info-circle-brand.svg";
import InfoCircleIcon from "../../assets/client/info-icon.svg";
import SelfCheckBag from "../../assets/client/self-check-bag.svg";
import Subtract from "../../assets/client/subtract.svg";

export enum IconName {
  // Flair
  AlertCircled = "alert-circled",
  Calendar = "calendar",
  CheckboxChecked = "checkbox-checked",
  CheckboxUnchecked = "checkbox-unchecked",
  ChevronDown = "chevron-down",
  ChevronUp = "chevron-up",
  Clock = "clock",
  Close = "close",
  Info = "info",
  LeftArrow = "left-arrow",
  Menu = "menu",
  PlaneBack = "plane-back",
  PlaneLanding = "plane-landing",
  PlaneTakeoff = "plane-takeoff",
  QuestionCircled = "question-circled",
  RightArrow = "right-arrow",
  LocationMarker = "location-marker",
  ShieldPayment = "shield-payment",
  MaintenanceIcon = "maintenance-icon",
  Star = "star",

  Airplane = "airplane",
  CirclePlane = "circle-plane",
  CloseModal = "close-modal",
  CloseModalPrimary = "close-modal-primary",
  CrossOutSign = "cross-out-sign",
  Dropdown = "dropdown",
  ErrorAlert = "error-alert",
  ErrorState = "error-state",
  WarningAlert = "warning-alert",
  BrandAlert = "brand-alert",
  FlightArrival = "flight-arrival",
  InfoCircle = "info-circle",
  InfoCircleBrand = "info-circle-brand",
  SeatSelection = "seat-selection",
  SelfCheckBag = "self-check-bag",
  CarryOnBaggage = "carry-on-baggage",
  CheckedBaggage = "checked-baggage",
  TripProtection = "trip-protection",
  RestrictionsChecked = "restrictions-checked",
  RestrictionsClose = "restrictions-close",
  RestrictionsPayment = "restrictions-payment",
  RestrictionsGeneric = "restrictions-generic",
  Guest = "guest",
  MultiPlanes = "multi-planes",
  B2BAirplaneIcon = "b2b-airplane-icon",
  ExternalLink = "external-link-icon",
  Add = "add",
  Subtract = "subtract",
  AirplaneDepart = "airplane-depart",
  AirplaneArrive = "airplane-arrive",
  ChevronBold = "chevron-bold",
}

type IconMap = {
  [name in IconName]: string;
};

export const iconMap: IconMap = {
  [IconName.Airplane]: Airplane,
  [IconName.CirclePlane]: CirclePlane,
  [IconName.CloseModal]: CloseModal,

  [IconName.CrossOutSign]: CrossOutSignIcon,
  [IconName.Dropdown]: DropdownIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.ErrorState]: ErrorStateIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.BrandAlert]: BrandAlertIcon,
  [IconName.FlightArrival]: FlightArrival,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoCircleBrand]: InfoCircleBrandIcon,
  [IconName.SeatSelection]: SeatSelection,
  [IconName.SelfCheckBag]: SelfCheckBag,
  [IconName.CarryOnBaggage]: CarryOnBag,
  [IconName.CheckedBaggage]: CheckedBag,
  [IconName.TripProtection]: DisruptionGuarantee,
  [IconName.RestrictionsChecked]: RestrictionsChecked,
  [IconName.RestrictionsClose]: RestrictionsClose,
  [IconName.RestrictionsPayment]: RestrictionsPayment,
  [IconName.RestrictionsGeneric]: RestrictionsGeneric,
  [IconName.Guest]: Guest,
  [IconName.ExternalLink]: ExternalLink,
  [IconName.Add]: Add,
  [IconName.Subtract]: Subtract,
  [IconName.AirplaneDepart]: AirplaneDepart,
  [IconName.AirplaneArrive]: AirplaneArrive,
  // Flair.
  [IconName.B2BAirplaneIcon]: PlaneForward,
  [IconName.CloseModalPrimary]: Close,
  [IconName.AlertCircled]: AlertCircled,
  [IconName.Calendar]: Calendar,
  [IconName.CheckboxChecked]: CheckboxChecked,
  [IconName.CheckboxUnchecked]: CheckboxUnchecked,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronUp]: ChevronUp,
  [IconName.Clock]: Clock,
  [IconName.Close]: Close,
  [IconName.Info]: Info,
  [IconName.LeftArrow]: LeftArrow,
  [IconName.Menu]: Menu,
  [IconName.PlaneBack]: PlaneBack,
  [IconName.PlaneLanding]: PlaneLanding,
  [IconName.PlaneTakeoff]: PlaneTakeoff,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.QuestionCircled]: QuestionCircled,
  [IconName.RightArrow]: RightArrow,
  [IconName.LocationMarker]: LocationMarker,
  [IconName.ShieldPayment]: ShieldPayment,
  [IconName.MaintenanceIcon]: MaintenanceIcon,
  [IconName.Star]: Star,
  [IconName.ChevronBold]: ChevronBold,
};

export interface IconProps {
  name: IconName;
  className?: string;
  ariaLabel?: string;
}

export const Icon = ({
  name,
  ariaLabel,
  ...props
}: IconProps): React.ReactElement => (
  <img
    src={iconMap[name]}
    alt={name}
    {...props}
    aria-label={ariaLabel ?? name}
  />
);
