import { AirCfarOffer } from "@b2bportal/air-cfar-api";

import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  ClientName,
  FintechCoverageType,
  FintechProductType,
  FtcType,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  DetailHalfSheet,
  FintechMobilePopoverCard,
  FintechPurchase,
  IconComponent,
  IconName,
  LoadingIndicator,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useEnableFintechPurchaseErrorState,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientContext } from "../../../App";
import {
  useFtcType,
  useGoToNextStep,
  useUpdateQueryParams,
} from "../../shop/hooks";
import { setSelectedCfarOffer } from "../actions/actions";
import {
  getCfarOffers,
  getCfarOffersTrackingProperties,
  getSelectedCfarOffer,
  getSelectedCfarOfferTrackingProperties,
  isCfarOffersLoading,
  isCfarOffersValid,
} from "../reducer";
import "./styles.scss";
import { FintechProduct } from "../../shop/components/FintechCustomizePage/FintechProduct";

const OPEN_CFAR_DETAILS = "open_cfar_details";

interface CfarOffersProps {
  goToNextStep?: (replace?: boolean) => void;
  isHalfSheet?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  hideContinueButton?: boolean;
}

export const CfarOffers = ({ goToNextStep, ...rest }: CfarOffersProps) => {
  const isLoading = useSelector(isCfarOffersLoading);
  const isValid = useSelector(isCfarOffersValid(true));

  if (!isValid) {
    goToNextStep?.(true);
  }

  return isLoading ? (
    <CfarLoading />
  ) : isValid ? (
    <CfarContent goToNextStep={goToNextStep} {...rest} />
  ) : null;
};

export const CfarContent = ({
  goToNextStep,
  onClose,
  isHalfSheet,
  onChange,
  hideContinueButton,
}: CfarOffersProps) => {
  const { t, formatCurrency } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const { setHeader } = useUberBridge();
  const enableFintechPurchaseErrorState = useEnableFintechPurchaseErrorState();

  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;

  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const ftcType = useFtcType();

  const cfarOffers = useSelector(getCfarOffers);
  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const cfarOffersTrackingProperties = useSelector(
    getCfarOffersTrackingProperties
  );
  const selectedCfarOfferTrackingProperties = useSelector(
    getSelectedCfarOfferTrackingProperties
  );

  const fullCoverageOffer = useMemo(
    () => cfarOffers?.find((offer) => offer?.coveragePercentage === "100"),
    [cfarOffers]
  );

  const partialCoverageOffer = useMemo(
    () => cfarOffers?.find((offer) => offer?.coveragePercentage === "80"),
    [cfarOffers]
  );

  const [open, setOpen] = useState<boolean>(false);
  const onOpenLearnMore = useCallback(() => {
    setOpen(true);
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, []);

  const handleSelect = useCallback(
    (offer?: AirCfarOffer) => {
      onChange?.();
      dispatch(setSelectedCfarOffer(offer));
    },
    [dispatch, onChange]
  );

  const noCoverageSelected = selectedCfarOffer === null;

  // On Load
  useEffect(() => {
    handleSelect(selectedCfarOffer);
    updateQueryParams(
      {
        [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedCfarOffer?.quoteId,
      },
      true
    );
  }, [handleSelect, updateQueryParams, selectedCfarOffer]);

  useEffect(() => {
    if (fullCoverageOffer && !enableFintechPurchaseErrorState) {
      handleSelect(fullCoverageOffer);
    }
  }, [fullCoverageOffer, handleSelect]);

  // Send tracking event
  useEffect(() => {
    trackEvent({
      eventName: CfarTrackingEvents.VIEWED_CFAR,
      ...cfarOffersTrackingProperties,
    });
  }, [cfarOffersTrackingProperties]);

  const selectNoCoverage = useCallback(() => {
    handleSelect(null);
  }, [handleSelect]);

  const handleGoToNext = useCallback(() => {
    updateQueryParams(
      {
        [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedCfarOffer?.quoteId,
      },
      true
    );

    goToNextStep?.();
  }, [goToNextStep, selectedCfarOffer?.quoteId, updateQueryParams]);

  const declineCoverage = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.DECLINED_CFAR,
      properties: undefined,
    });

    handleGoToNext();
  }, [handleGoToNext]);

  const addCoverage = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.ADDED_CFAR,
      ...selectedCfarOfferTrackingProperties,
    });
    handleGoToNext();
  }, [handleGoToNext, selectedCfarOfferTrackingProperties]);

  useEffect(() => {
    setHeader({
      customButtons: [
        {
          text: t("learnMore"),
          icon: "CIRCLEI",
          action: {
            name: OPEN_CFAR_DETAILS,
            callback: onOpenLearnMore,
          },
        },
      ],
      title: "",
    });
  }, [onOpenLearnMore, setHeader, t]);

  const hopperOptions = useMemo(() => {
    return [
      fullCoverageOffer && {
        value: FintechCoverageType.Full,
        title: t("cfar.fullCoverageTitle"),
        cost: formatCurrency(fullCoverageOffer?.premiumAmount?.fiat.value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        selected: selectedCfarOffer?.coveragePercentage === "100",
        onClick: () => handleSelect(fullCoverageOffer),
        recommended: t("cfar.mostPopular"),
      },
      partialCoverageOffer && {
        value: FintechCoverageType.Partial,
        title: t("cfar.partialCoverageTitle"),
        cost: formatCurrency(partialCoverageOffer?.premiumAmount?.fiat.value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        selected: selectedCfarOffer?.coveragePercentage === "80",
        onClick: () => handleSelect(partialCoverageOffer),
      },
      {
        value: FintechCoverageType.None,
        title: t("cfar.noCoverageTitle"),
        description: t("cfar.noCoverageDescription"),
        selected: noCoverageSelected,
        onClick: () => selectNoCoverage(),
      },
    ];
  }, [
    formatCurrency,
    fullCoverageOffer,
    handleSelect,
    noCoverageSelected,
    partialCoverageOffer,
    selectNoCoverage,
    selectedCfarOffer?.coveragePercentage,
    t,
  ]);

  const options = useMemo(() => {
    return [
      fullCoverageOffer && {
        value: FintechCoverageType.Full,
        title: t("cfarOffers.fullCoverage"),
        cost: formatCurrency(fullCoverageOffer?.premiumAmount?.fiat.value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        description:
          ftcType === FtcType.NoFtc
            ? [
                t("cfarOffers.fullCoverageTextNoFtc1"),
                t("cfarOffers.fullCoverageTextNoFtc2"),
              ]
            : [
                t("cfarOffers.fullCoverageText1"),
                t("cfarOffers.fullCoverageText2"),
                t("cfarOffers.fullCoverageText3"),
              ],
        selected: selectedCfarOffer?.coveragePercentage === "100",
        onClick: () => handleSelect(fullCoverageOffer),
        ctaLabel: t("acceptCta"),
      },
      partialCoverageOffer && {
        value: FintechCoverageType.Partial,
        title: t("cfarOffers.partialCoverage"),
        cost: formatCurrency(partialCoverageOffer?.premiumAmount?.fiat.value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        description:
          ftcType === FtcType.NoFtc
            ? [
                t("cfarOffers.partialCoverageTextNoFtc1"),
                t("cfarOffers.partialCoverageTextNoFtc2"),
              ]
            : [
                t("cfarOffers.partialCoverageText1"),
                t("cfarOffers.partialCoverageText2"),
                t("cfarOffers.partialCoverageText3"),
              ],

        selected: selectedCfarOffer?.coveragePercentage === "80",
        onClick: () => handleSelect(partialCoverageOffer),
        ctaLabel: t("declineCta"),
      },
      {
        value: FintechCoverageType.None,
        title: t("cfarOffers.noCoverage"),
        cost: formatCurrency(0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        description: [t("cfarOffers.noCoverageText")],
        selected: noCoverageSelected,
        onClick: selectNoCoverage,
        ctaLabel: t("declineCta"),
      },
    ];
  }, [
    formatCurrency,
    ftcType,
    fullCoverageOffer,
    handleSelect,
    noCoverageSelected,
    partialCoverageOffer,
    selectNoCoverage,
    selectedCfarOffer?.coveragePercentage,
    t,
  ]);

  const CfarOfferContent = () => (
    <div className="cfar-offers-container">
      <Slot
        id="flights-fintech-cfar-fintech-purchase"
        isHalfSheet={isHalfSheet}
        className="cfar"
        options={options.filter(Boolean)}
        headerTitle={isHalfSheet ? null : t("cfarOffers.title")}
        headerSubtitle={t("cfarOffers.subtext")}
        headerImgSrc={
          isHalfSheet
            ? null
            : clientContext.assets && clientContext.assets["shield"]
        }
        clientAssets={clientContext.assets}
        ctaLabel={t(
          selectedCfarOffer && !noCoverageSelected
            ? "cfarOffers.coverageButtonText"
            : "cfarOffers.declineCoverageButtonText"
        )}
        disabled={selectedCfarOffer === undefined}
        handleContinue={
          hideContinueButton
            ? undefined
            : noCoverageSelected
            ? declineCoverage
            : addCoverage
        }
        component={
          <FintechPurchase
            isHalfSheet={isHalfSheet}
            className="cfar"
            options={options.filter(Boolean)}
            headerTitle={isHalfSheet ? null : t("cfarOffers.title")}
            headerSubtitle={t("cfarOffers.subtext")}
            headerImgSrc={
              isHalfSheet
                ? null
                : clientContext.assets && clientContext.assets["shield"]
            }
            clientAssets={clientContext.assets}
            ctaLabel={t(
              selectedCfarOffer && !noCoverageSelected
                ? "cfarOffers.coverageButtonText"
                : "cfarOffers.declineCoverageButtonText"
            )}
            disabled={selectedCfarOffer === undefined}
            handleContinue={
              hideContinueButton
                ? undefined
                : noCoverageSelected
                ? declineCoverage
                : addCoverage
            }
          />
        }
      />
      <DetailHalfSheet
        open={open}
        onClose={() => setOpen(false)}
        description={<I18nMarkup tKey={"cfarOffers.learnMoreDescription"} />}
        productType={FintechProductType.CfarPurchase}
        descriptionAdditional
        header={t("cfarOffers.learnMoreHeader")}
        title={t("cfarOffers.learnMoreTitle")}
      />
    </div>
  );

  const onNext = useGoToNextStep();

  if (isHopper) {
    return (
      <FintechProduct
        options={hopperOptions.filter(Boolean)}
        type={FintechProductType.CfarPurchase}
        header={t?.("cfar.header")}
        bodyText={[
          "cfar.description1",
          "cfar.description2",
          "cfar.description3",
        ]}
        goToNextStep={onNext}
        disableContinueButton={selectedCfarOffer === undefined}
        hideContinueButton={hideContinueButton}
      />
    );
  }

  return isHalfSheet ? (
    <FintechMobilePopoverCard
      centered
      open={isHalfSheet}
      onClose={onClose}
      fullScreen={false}
      scroll="body"
      className="cfar-half-sheet"
      contentClassName="cfar-half-sheet-content"
      headerElement={t("cfarOffers.title")}
      topLeftButton={
        <button className="cfar-half-sheet-close-button" onClick={onClose}>
          <IconComponent name={IconName.Close} />
        </button>
      }
    >
      <CfarOfferContent />
    </FintechMobilePopoverCard>
  ) : (
    <CfarOfferContent />
  );
};

const CfarLoading = () => {
  const { t } = useI18nContext();
  return (
    <LoadingIndicator
      className="cfar-loader"
      indicatorSize={"small"}
      indicator={B2BSpinner}
      message={t("loading")}
    />
  );
};
