export enum FlightSearchEvents {
  view_search = "view_search",
}

export enum BestPriceGuaranteeEvents {
  viewed_best_price_guarantee = "viewed_best_price_guarantee",
}

export enum BestPriceGuaranteeEventProperties {
  product = "product",
}
export enum BestPriceGuaranteeEventProductTypes {
  Flights = "Flights",
  Hotels = "Hotels",
}

export enum ExchangeTypeEventEnum {
  DatesOnly = "dates_only",
  AirportAndDates = "airport_and_dates",
  AirportOnly = "airport_only",
  Same = "same",
}

export enum PriceDropEvents {
  viewed_price_drop = "viewed_price_drop",
  viewed_price_drop_details = "viewed_price_drop_details",
  price_drop_added = "price_drop_added",
}
